import React from "react";
import Checkbox from "../Checkbox";
import SmallSpinner from "../SmallSpinner";
import { sanitizeInput, Validator } from "../Validation/Validator";
import { getAllFormElements, showValidationErrors, resetValidationErrors } from "../Utilities";
import { setToaster } from "../Toaster";
import { ToastContainer } from "react-toastify";
import { Redirect } from "../Utilities";
import { Link } from "react-router-dom";
import { login } from "../Account/AccountUtil";
import { AddTokenToLocalStorage } from "../Account/AuthenticationContext";
import { AxiosError } from "axios";
import ValidationSummary from "../Validation/ValidationSummary";
import { userContext } from "../Menu/MenuPicker";

interface Props {
  onLoginStatusChange: any;
}

interface State {
  email: string;
  password: string;
  rememberMe: boolean;
  redirect: boolean;
  formErrors: any;
  isLoading: boolean;
}

class LogInForm extends React.Component<Props, State> {
  static contextType = userContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      rememberMe: false,
      redirect: false,
      formErrors: [],
      isLoading: false,
    };
    this.handleBlurValidation = this.handleBlurValidation.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleLoginStatusChange = this.handleLoginStatusChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
  }
  login = (data: any): void => {
    //process results from ajax call
    const successCallback = (result: any): void => {
      if (result && result !== undefined && result.Token !== null && result.Token !== undefined) {
        //add local storage cookie
        AddTokenToLocalStorage(result.Token);
        //bubble up event to router
        this.handleLoginStatusChange(true);

        //redirect to family page
        this.setState({
          isLoading: false,
          redirect: true,
        });
      } else {
        this.setState({ isLoading: false });
        if (result.StatusCode && result.StatusCode === 401) {
          setToaster({
            state: { error: true },
            message: "Login failed: Check username and password",
          });
        } else if (result.Success === false) {
          setToaster({
            state: { error: true },
            message: "Login failed: " + result.Message,
          });
        } else {
          setToaster({
            state: { error: true },
            message: "Login failed: Unknown error ",
          });
        }
      }
    };
    //handle errors
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        setToaster({
          state: { error: true },
          message: error.response ? error.message : "Unknown Error",
        });
        this.setState({ isLoading: false });
      }
    };
    login({
      url: "Authentication/Authenticate",
      data: data,
      successCallback,
      errorCallback,
    });
  };
  handleBlurValidation = (e: any) => {
    let errors = Validator({ fields: e.target });
    if (errors.length === 0) {
      this.setState({ formErrors: [] }, () => {
        resetValidationErrors(e.target);
      });
    } else {
      this.setState({ formErrors: errors }, () => {
        showValidationErrors(this.state.formErrors);
      });
    }
  };
  handleFocus = (e: any) => {
    this.setState({ formErrors: [] }, () => {
      resetValidationErrors(e.target);
    });
  };
  handleEmailChange = (e: any) => {
    let email = sanitizeInput(e.target.value, e.target.type);
    this.setState({ email: email });
  };
  handlePasswordChange = (e: any) => {
    this.setState({ password: e.target.value });
  };
  handleCheckBoxChange = (e: any) => {
    if (e !== null && e !== undefined) {
      this.setState({ rememberMe: e.target.checked });
      //is this a required field
      let errors = Validator({ fields: e.target });
      if (errors.length === 0) {
        this.setState({ formErrors: [] }, () => {
          resetValidationErrors(e.target);
        });
      } else {
        this.setState({ formErrors: errors }, () => {
          showValidationErrors(this.state.formErrors);
        });
      }
    }
  };
  handleLoginStatusChange = (status: boolean) => {
    this.props.onLoginStatusChange(status);
  };
  handleSubmit = (e: any) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    let context: any = this.context;
    context.userCallback({});

    const email = this.state.email.trim();
    const password = this.state.password.trim();
    const rememberMe = this.state.rememberMe;
    let form = document.getElementById("loginForm");
    let allFormElements = getAllFormElements({ element: form ? form : e });
    let errors = Validator({ fields: allFormElements });

    if (errors.length === 0) {
      this.login({
        Username: email,
        Password: password,
        RememberMe: rememberMe,
      });
      this.setState({ formErrors: [] });
    } else {
      this.setState({ isLoading: false, formErrors: errors }, () => {
        showValidationErrors(this.state.formErrors);
      });
    }
  };
  render() {
    return (
      <div className="card-form">
        <h5 className="card-title">Log In</h5>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SmallSpinner isLoading={this.state.isLoading} actionText="Please Wait" />
            </div>
          </div>
        </div>
        <ValidationSummary Errors={this.state.formErrors} />
        <form id="loginForm" onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="email" className="label-dark">
              Email Address
            </label>
            <input
              id="email"
              className="form-control border-input"
              type="email"
              value={this.state.email}
              onChange={this.handleEmailChange}
              onBlur={this.handleBlurValidation}
              onFocus={this.handleFocus}
              placeholder="Email Address"
              data-required="true"
              data-validationname="Email Address"
              data-id="email"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password" className="label-dark">
              Password
            </label>
            <input
              id="password"
              className="form-control border-input"
              type="password"
              value={this.state.password}
              onChange={this.handlePasswordChange}
              onBlur={this.handleBlurValidation}
              onFocus={this.handleFocus}
              placeholder="Password"
              data-required="true"
              data-validationname="Password"
              data-id="password"
            />
          </div>
          <div className="col-sm-auto">
            <div className="form-group">
              <Checkbox
                id="rememberme"
                label="Remember Me?"
                checked={this.state.rememberMe}
                onSelecting={this.handleCheckBoxChange}
                datarequired={false}
                labelClass="label-dark"
                data-validationname="Remember Me"
                data-id="rememberme"
              />
            </div>
          </div>
          <div className="card-footer">
            <div className="row">
              <div className="col-md-5">
                <div className="form-group">
                  <button className="btn btn-default btn-round" data-id="loginBtn">
                    Log In
                  </button>
                </div>
              </div>
              <div className="col-md-7 ml-auto mr-auto">
                <Link to="../forgotpasswordemail">Forgot Password?</Link>
              </div>
            </div>
          </div>
        </form>
        <ToastContainer autoClose={2000} />
        {this.state.redirect ? <Redirect path="/family" /> : <></>}
      </div>
    );
  }
}

export default LogInForm;
