import React from "react";
import { AxiosError } from "axios";
import { useContext } from "react";
import LogInForm from "./LogInForm";
import { navigationContext } from "../../routes/root";
import { userContext } from "../Menu/MenuPicker";
import navValues from "../Menu/NavValues";
import { GetUserFromToken } from "../../components/Account/AuthenticationContext";

interface Props {
  title: string;
}

const Login = (props: Props): any => {
  const { navigate } = useContext(navigationContext) as any;
  const { user, userCallback } = useContext(userContext) as any;
  let keyLength: number = Object.keys(user).length;

  const handleLoginChange = (status: any) => {
    if (status === true) {
      navigate(navValues.loggedIn);
      getUser();
    } else {
      navigate(navValues.loggedOut);
    }
  };
  const getUser = () => {
    //process results from ajax call
    const successCallback = (result: any): void => {
      if (result && result !== undefined && result.Data && result.Data !== undefined) {
        //do logic
        if (result.Success === true) {
          userCallback(result.Data);
        }
      }
    };
    //handle errors
    const errorCallback = (error: AxiosError): void => {
      if (error) {
      }
    };
    GetUserFromToken({
      url: "Authentication/GetUser",
      data: {},
      successCallback,
      errorCallback,
    });
  };
  return (
    <>
      <div>
        <div className="col-md-12">
          <h3 className="title">{props.title}</h3>
        </div>
        <hr></hr>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <LogInForm onLoginStatusChange={handleLoginChange} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
