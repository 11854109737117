import React from "react";
import MyAccountForm from "./MyAccountForm";
import { Redirect } from "../Utilities";

interface Props {
  Title: string;
}

interface State {
  isLoading: boolean;
  redirect: boolean;
}

class MyAccount extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      redirect: false,
    };
  }
  render() {
    return (
      <>
        <div>
          <div className="col-md-12">
            <h3 className="title">{this.props.Title}</h3>
          </div>
          <hr></hr>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="card-info">
                <h6>Password Policy</h6>
                <p>
                  Passwords must be at least 6 characters.
                  <br /> Have at least one non alphanumeric character.
                  <br /> Have at least one lowercase character.
                  <br />
                  Have at least one uppercase character.
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <MyAccountForm />
            </div>
          </div>
        </div>
        {this.state.redirect ? <Redirect path="/login" isLogout={true} /> : <></>}
      </>
    );
  }
}

export default MyAccount;
