import React from "react";
import SmallSpinner from "./../components/SmallSpinner";
import { useContext, useEffect } from "react";
import TokenStatus from "../components/Account/TokenStatus";
import { userContext } from "../components/Menu/MenuPicker";
import { Redirect } from "../components/Utilities";
import { GetTokenFromLocalStorage } from "./../components/Account/AuthenticationContext";
import Users from "../components/Admin/Users";

function UsersRoute(): any {
  const { user } = useContext(userContext) as any;

  let token: string | null = GetTokenFromLocalStorage();
  let haveToken: boolean = token === null || token === undefined || token === "" ? false : true;

  useEffect(() => {
    if (user !== null && user !== undefined) {
    }
  }, [user]);

  if (user === null || user === undefined || (Object.keys(user).length === 0 && haveToken)) {
    return (
      <>
        <div id="users">
          <SmallSpinner isLoading={true} />
        </div>
      </>
    );
  } else if (!haveToken) {
    return <Redirect path="/login" isLogout={true} />;
  } else {
    return (
      <>
        <TokenStatus />
        <div id="users">
          <Users Title="Users" />
        </div>
      </>
    );
  }
}

export default UsersRoute;
