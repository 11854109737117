declare global {
  interface Window {
    grecaptcha: any;
  }
}

export default class Recaptcha {
  siteKey: string;
  action: string;
  constructor(siteKey: string, action: string) {
    this.siteKey = siteKey;
    this.action = action;
    loadReCaptcha(siteKey);
  }
  async getToken(): Promise<string> {
    let token = "";
    await window.grecaptcha.execute(this.siteKey, { action: this.action }).then((res: string) => {
      token = res;
    });
    return token;
  }
}

const loadReCaptcha = (siteKey: string) => {
  const script = document.createElement("script");
  script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${siteKey}`;
  document.body.appendChild(script);
};

/*const Recaptcha = () => {
  const siteKey: string = "6LeBFIQmAAAAAGkvJWpAYJMzfQSSzsGUZldBkMXl";
  const handleSubmit = (e: any) => {
    let winObject: any = window;
    let recaptchaValue = winObject["onRecaptcha"](e);
    return recaptchaValue;
    /*window.grecaptcha.ready(() => {
      window.grecaptcha.execute(siteKey, { action: "submit" }).then((token: any) => {
        // ...
        var test = token;
      });
    });
  };

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js?render=" + siteKey;
    document.body.appendChild(script);

    const grecaptcha = document.createElement("script");
    var inlineCode = document.createTextNode(
      "function onRecaptcha(e) {e.preventDefault();grecaptcha.ready(function() {grecaptcha.execute('" +
        siteKey +
        "', {action: 'submit'}).then(function(token) { return 1 });});}"
    );
    grecaptcha.appendChild(inlineCode);
    document.body.appendChild(grecaptcha);
  }, []);

  return <div className="g-recaptcha" data-sitekey={siteKey} data-size="invisible"></div>;
};

export default Recaptcha;*/
