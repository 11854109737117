import React from "react";
import { faChildren, faPersonBiking, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function HomeRoute(): any {
  return (
    <div id="home">
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-8 ml-auto mr-auto text-left">
              <h2 className="title">About Kidpoint</h2>
              <h5 className="description">
                I developed this app because I wanted my daughter to appreciate the value of the
                things she owns.
                <br /> The app itself addresses the three main themes below, in a way that doesn't
                require trying to explain economics to a child.
                <br /> It's designed to be a tool in the parenting toolbox.
              </h5>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-4">
              <div className="info">
                <div className="icon icon-primary">
                  <FontAwesomeIcon icon={faChildren} size="lg" />
                </div>
                <div className="description">
                  <h4 className="info-title">Reward</h4>
                  <p>
                    Earn reward points for good behaviour, like doing chores and homework on time.
                    You decide the criteria.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="info">
                <div className="icon icon-warning">
                  <FontAwesomeIcon icon={faStar} size="lg" />
                </div>
                <div className="description">
                  <h4 className="info-title">Save</h4>
                  <p>
                    Learn about saving and the fact you can't always get what you want, when you
                    want it.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="info">
                <div className="icon icon-danger">
                  <FontAwesomeIcon icon={faPersonBiking} size="lg" />
                </div>
                <div className="description">
                  <h4 className="info-title">Value</h4>
                  <p>
                    Appreciate the value of the things you have worked for and earned, creating an
                    association between effort and value.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeRoute;
