import React from "react";
import SmallSpinner from "../SmallSpinner";
import { faGift, faTrash, faStar, faCoins } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { SmallAlert } from "../SmallAlert";
import { getChildDetails, updateChildName, deleteChild } from "../Family/Family";
import { sanitizeInput, Validator } from "../Validation/Validator";
import { getAllFormElements, showValidationErrors, resetValidationErrors } from "../Utilities";
import ValidationSummary from "../Validation/ValidationSummary";
import TokenContext from "../Context/TokenContext";
import { AxiosError } from "axios";
import { setToaster } from "../Toaster";
import { ToastContainer } from "react-toastify";
import { Redirect } from "../Utilities";

interface Props {
  ChildId: any;
}

interface State {
  data: any;
  name: string;
  numberOfItems: number;
  tokensTotal: number;
  totalSelectedItemsTokenValue: number;
  isLoading: boolean;
  isDeleting: boolean;
  redirect: boolean;
  profileFormErrors: any;
  CurrencySymbol: string;
  Balance: string;
}

class ChildProfile extends React.Component<Props, State> {
  static contextType = TokenContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      name: "",
      numberOfItems: 0,
      tokensTotal: 0,
      totalSelectedItemsTokenValue: 0,
      isLoading: false,
      isDeleting: false,
      redirect: false,
      profileFormErrors: [],
      CurrencySymbol: "£",
      Balance: "",
    };
    this.handleBlurValidation = this.handleBlurValidation.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNameTextChange = this.handleNameTextChange.bind(this);
    this.handleTokenChange = this.handleTokenChange.bind(this);
    this.handleDeleting = this.handleDeleting.bind(this);
    this.handleChildDelete = this.handleChildDelete.bind(this);
  }
  loadChildData(): void {
    if (this.props.ChildId !== null && this.props.ChildId !== undefined) {
      const successCallback = (result: any): void => {
        if (result && result.Data && result.Data !== undefined) {
          this.setState(
            {
              data: result.Data,
              name: result.Data.Name,
              numberOfItems: result.Data.Items ? result.Data.Items : 0,
              tokensTotal: result.Data.TokensTotal,
              totalSelectedItemsTokenValue: result.Data.TotalSelectedItemsTokenValue,
              CurrencySymbol: result.Data.CurrencySymbol,
              Balance: result.Data.Balance,
            },
            () => {
              //ready to render the child props
              this.setState({ isLoading: false });
            }
          );
        }
      };
      const errorCallback = (error: AxiosError): void => {
        if (error) {
          this.setState({ isLoading: false });
          setToaster({
            state: { error: true },
            message: error.response ? error.message : "Unknown Error",
          });
          if (error.response && error.response.status && error.response.status === 401) {
            this.setState({ redirect: true });
          }
        }
      };
      getChildDetails({
        url: "ChildService/GetChildDetails",
        data: { childId: this.props.ChildId },
        successCallback,
        errorCallback,
      });
    }
  }
  updateChild = (data: any): void => {
    //process results from ajax call
    const successCallback = (result: any): void => {
      if (result && result !== undefined) {
        //do logic
        if (result.Success === true) {
          setToaster({
            state: { success: true },
            message: "Success: Updated",
          });

          this.setState({ isLoading: false });

          //update the tokenchange value in the parent context
          let context: any = this.context;
          context.updateTokenValue(true);
        } else {
          setToaster({ state: { error: true }, message: "Error: " + result.Message });
          this.setState({ isLoading: false });
        }
      }
    };
    //handle errors
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({ isLoading: false });
        setToaster({
          state: { error: true },
          message: error.response ? error.message : "Unknown Error",
        });
        if (error.response && error.response.status && error.response.status === 401) {
          this.setState({ redirect: true });
        }
      }
    };
    updateChildName({
      url: "ChildService/UpdateChildName",
      data: data,
      successCallback,
      errorCallback,
    });
  };
  deleteChild = (data: any): void => {
    //process results from ajax call
    const successCallback = (result: any): void => {
      if (result && result !== undefined) {
        //do logic
        if (result.Success === true) {
          setToaster({
            state: { success: true },
            message: "Success: Child removed",
          });

          //redirect back to family page
          this.setState({ isLoading: false, redirect: true });
        } else {
          setToaster({ state: { error: true }, message: "Error: " + result.Message });
        }
      }
    };
    //handle errors
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({ isLoading: false });
        setToaster({
          state: { error: true },
          message: error.response ? error.message : "Unknown Error",
        });
        if (error.response && error.response.status && error.response.status === 401) {
          this.setState({ redirect: true });
        }
      }
    };
    deleteChild({
      url: "ChildService/DeleteChild",
      data: data.childId,
      successCallback,
      errorCallback,
    });
  };
  componentDidMount() {
    this.loadChildData();
  }
  componentWillUnmount() {
    this.setState({
      isDeleting: false,
    });
  }
  handleBlurValidation = (e: any) => {
    let errors = Validator({ fields: e.target });
    if (errors.length === 0) {
      this.setState({ profileFormErrors: [] }, () => {
        resetValidationErrors(e.target);
      });
    } else {
      this.setState({ profileFormErrors: errors }, () => {
        showValidationErrors(this.state.profileFormErrors);
      });
    }
  };
  handleFocus = (e: any) => {
    this.setState({ profileFormErrors: [] }, () => {
      resetValidationErrors(e.target);
    });
  };
  handleSubmit = (e: any): void => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const name = this.state.name.trim();
    let totalTokens: number = this.state.tokensTotal;

    let form = document.getElementById("changeChildNameForm");
    let allFormElements = getAllFormElements({ element: form ? form : e });
    let errors = Validator({ fields: allFormElements });

    if (errors.length === 0) {
      this.updateChild({ Name: name, ChildId: this.props.ChildId, TokensTotal: totalTokens });
      this.setState({ profileFormErrors: [] });
    } else {
      this.setState({ isLoading: false, profileFormErrors: errors }, () => {
        showValidationErrors(this.state.profileFormErrors);
      });
    }
  };
  handleNameTextChange = (e: any): void => {
    let childName = sanitizeInput(e.target.value, e.target.type);
    this.setState({ name: childName });
  };
  handleTokenChange = (e: any): void => {
    if (e.target.value < 0) {
      e.target.value = 0;
    }
    this.setState({ tokensTotal: e.target.value });
  };
  handleDeleting = (e: any): void => {
    e.preventDefault();
    this.setState({ isDeleting: true });
  };
  handleChildDelete = (e: any): void => {
    e.preventDefault();
    this.setState({ isDeleting: false });
    this.deleteChild({ childId: this.props.ChildId });
  };
  render() {
    return (
      <>
        {/*<div className="card-info">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum.
          </p>
        </div>
    <hr></hr>*/}
        <div className="card card-profile-details">
          <div className="card-avatar border-white">
            <h5 className="card-title-custom">Details for {this.state.name}</h5>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <SmallSpinner isLoading={this.state.isLoading} actionText="Please Wait" />
              </div>
            </div>
          </div>
          <ValidationSummary Errors={this.state.profileFormErrors} />
          <div className="row">
            <div className="col-md-12">
              <div className="card-body text-left">
                <form id="changeChildNameForm" onSubmit={this.handleSubmit}>
                  <h6 className="card-category-profile">Name:</h6>
                  <div className="form-group">
                    <input
                      id="child_name"
                      className="form-control border-input"
                      type="text"
                      placeholder="Child's First name"
                      onChange={this.handleNameTextChange}
                      onBlur={this.handleBlurValidation}
                      onFocus={this.handleFocus}
                      value={this.state.name}
                      data-required="true"
                      data-validationname="Child Name"
                      data-id={this.props.ChildId}
                    />
                  </div>
                  {/*<h4 className="card-title">{this.state.data.Name}</h4>*/}
                  <h6 className="card-category-profile">
                    Points:&nbsp;
                    <span>
                      <FontAwesomeIcon icon={faStar} className="tokens" />
                    </span>
                  </h6>
                  <div className="form-group">
                    <input
                      id="total_token_value"
                      className="form-control border-input"
                      type="number"
                      placeholder="Points"
                      value={this.state.tokensTotal}
                      onChange={this.handleTokenChange}
                      onBlur={this.handleBlurValidation}
                      onFocus={this.handleFocus}
                      data-required="true"
                      data-validationname="Total Token Value"
                      data-id={this.props.ChildId}
                    />
                  </div>
                  {this.state.Balance !== null &&
                this.state.Balance !== undefined &&
                this.state.Balance !== "0.00" && (
                  <h6 className="card-category-profile">
                    <span>
                      <FontAwesomeIcon icon={faCoins} className="tokens" />
                    </span>
                    &nbsp; Earned: &nbsp;
                    {this.state.CurrencySymbol + this.state.Balance.toString()}
                  </h6>
                )}
                  <h6 className="card-category-profile">
                    <span>
                      <FontAwesomeIcon icon={faGift} className="gift" />
                    </span>
                    &nbsp; Items: &nbsp;
                    {this.state.numberOfItems}
                  </h6>
                  <div className="card-footer-profile">
                    <button
                      type="button"
                      id={"edit_" + this.props.ChildId}
                      onClick={this.handleSubmit}
                      className="btn btn-just-icon btn-outline-edit"
                      data-tooltip-content="Update Name"
                      data-id={this.props.ChildId}
                    >
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                    <button
                      type="button"
                      id={"delete_" + this.props.ChildId}
                      onClick={this.handleDeleting}
                      className="btn btn-just-icon btn-outline-minus"
                      data-tooltip-content="Remove"
                      data-toggle="modal"
                      data-target="#smallAlertModalChild"
                      data-id={this.props.ChildId}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <ReactTooltip
          anchorId={"edit_" + this.props.ChildId}
          place="bottom"
          variant="dark"
          content="Update Name"
        />
        <ReactTooltip
          anchorId={"delete_" + this.props.ChildId}
          place="bottom"
          variant="dark"
          content={"Remove " + this.state.name}
        />
        <SmallAlert
          id="smallAlertModalChild"
          message={"Are you sure you want to delete " + this.state.name}
          cancel="Cancel"
          confirm="Yes"
          isVisible={this.state.isDeleting}
          onDelete={this.handleChildDelete}
        />
        <ToastContainer autoClose={2000} />
        {this.state.redirect ? <Redirect path="/family" /> : <></>}
      </>
    );
  }
}

export default ChildProfile;
