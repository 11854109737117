import React from "react";
import SmallSpinner from "../SmallSpinner";
import { getUsers } from "../Admin/Admin";
import { Redirect } from "../Utilities";
import { AxiosError } from "axios";
import { setToaster } from "../Toaster";
import { ToastContainer } from "react-toastify";
import { DataGrid } from "../Grid/DataGridUsersToRoles";
import { userContext } from "../Menu/MenuPicker";
import { getRoles } from "../Admin/Admin";

interface Props {
  Title: string;
}

interface State {
  isLoading: boolean;
  renderCount: number;
  redirect: boolean;
  user: any;
  RoleSelectOptions: any;
  appUsers: any;
  appUsersAndRoles: any;
}

class AddUserToRole extends React.Component<Props, State> {
  static contextType = userContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      renderCount: 0,
      redirect: false,
      user: {},
      RoleSelectOptions: [],
      appUsers: {},
      appUsersAndRoles: {},
    };
    this.handleRoleDeleted = this.handleRoleDeleted.bind(this);
  }
  populateUser(): void {
    try {
      let context: any = this.context;
      this.setState({ user: context.user }, () => {
        this.getUserRoles();
      });
    } catch (error: any) {}
  }
  buildUserOnjects(): void {
    if (this.state.appUsers !== null && this.state.appUsers !== null) {
      let usersWithRoles: any | null = this.state.appUsers.Data.map((user: any) => {
        user.Roles = this.state.RoleSelectOptions;
        return user;
      });
      if (usersWithRoles !== null && usersWithRoles !== undefined) {
        this.setState({ appUsersAndRoles: usersWithRoles }, () => {
          this.setState({
            renderCount: this.state.renderCount + 1,
          });
        });
      }
    }
  }
  getUserRoles(): void {
    const successCallback = (result: any): void => {
      if (result && result.Data) {
        this.setState(
          {
            RoleSelectOptions: result.Data,
          },
          () => {
            this.loadGridData();
          }
        );
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        setToaster({
          state: { error: true },
          message: error.response ? error.message : "Unknown Error",
        });
        if (error.response && error.response.status && error.response.status === 401) {
          this.setState({ redirect: true });
        }
      }
    };
    getRoles({
      url: "Admin/GetRoles",
      data: {},
      successCallback,
      errorCallback,
    });
  }
  loadGridData(): void {
    const successCallback = (result: any): void => {
      if (result && result.Data) {
        this.setState(
          {
            isLoading: false,
            appUsers: result,
          },
          () => {
            //build object
            this.buildUserOnjects();
          }
        );
      } else {
        this.setState({ isLoading: false });
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({ isLoading: false }, () => {
          setToaster({
            state: { error: true },
            message: error.response ? error.message : "Unknown Error",
          });
        });
        if (error.response && error.response.status && error.response.status === 401) {
          this.setState({ redirect: true });
        }
      }
    };
    getUsers({
      url: "Admin/GetUsers",
      data: {},
      successCallback,
      errorCallback,
    });
  }
  handleRoleDeleted() {
    this.setState({
      renderCount: this.state.renderCount + 1,
    });
  }
  buildColumns(): any {
    const columnHeaders = [
      {
        DataPropertyName: "Id",
        IsIdColumn: true,
        ShowColumn: false,
      },
      {
        DataPropertyName: "UserName",
        ColumnAliasName: "User Name",
        ShowColumn: true,
      },
      {
        DataPropertyName: "RoleId",
        ColumnAliasName: "Role",
        InputType: "select",
        IsRequired: true,
        ShowColumn: true,
      },
    ] as any;

    return columnHeaders;
  }
  componentDidMount(): void {
    this.populateUser();
  }
  render() {
    return (
      <>
        <div>
          <div className="col-md-12">
            <h3 className="title">{this.props.Title}</h3>
          </div>
          <hr></hr>
        </div>
        <div className="row">
          <div className="col-md-12">
            <SmallSpinner isLoading={this.state.isLoading} actionText="Adding Role" />
          </div>
          <div className="col-md-12">
            <DataGrid
              key={"dataGrid_" + this.state.renderCount}
              ColumnArray={this.buildColumns()}
              SelectAPI="Admin/GetUsers"
              UpdateAPI="Admin/UpdateUserRole"
              DeleteAPI="Admin/DeleteUser"
              MaxNumberOfRowsPerPage={50}
              GridName="userroles"
              AppUserData={this.state.appUsersAndRoles}
            />
          </div>
        </div>
        <ToastContainer autoClose={2000} />
        {this.state.redirect ? <Redirect path="/login" isLogout={true} /> : <></>}
      </>
    );
  }
}

export default AddUserToRole;
