import React from "react";
import SmallSpinner from "../SmallSpinner";
import ChildProfile from "./ChildProfile";
import ItemList from "../Family/ItemList";
import { sanitizeInput, Validator } from "../Validation/Validator";
import { getAllFormElements, showValidationErrors, resetValidationErrors } from "../Utilities";
import { addItem } from "../Family/Items";
import { Redirect } from "../Utilities";
import { AxiosError } from "axios";
import ValidationSummary from "../Validation/ValidationSummary";
import { setToaster } from "../Toaster";
import { ToastContainer } from "react-toastify";
import TokenContext from "../Context/TokenContext";
import { userContext } from "../Menu/MenuPicker";

interface Props {
  title: string;
  ChildId: string;
}

interface State {
  data: any;
  isLoading: boolean;
  itemName: string;
  numberOfTokensNeeded: any;
  renderCount: number;
  itemFormErrors: any;
  redirect: boolean;
}

class ChildDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      isLoading: false,
      itemName: "",
      numberOfTokensNeeded: 0,
      renderCount: 0,
      itemFormErrors: [],
      redirect: false,
    };
    this.handleBlurValidation = this.handleBlurValidation.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleTokenChange = this.handleTokenChange.bind(this);
    this.setTokenChangedValue = this.setTokenChangedValue.bind(this);
  }

  //this is for tokenContext
  //called when children perform updates to token values
  //incrementing the renderCount will force children to update
  setTokenChangedValue = (reload: boolean): any => {
    if (reload) {
      this.setState({
        renderCount: this.state.renderCount + 1,
      });
    }
  };
  //Itemlist methods
  postData(data: any): void {
    //process results from ajax call
    const successCallback = (result: any): void => {
      if (result && result !== undefined) {
        //do logic
        if (result.Success === true) {
          setToaster({
            state: { success: true },
            message: "Success: Item Added",
          });

          //force a rerender of ItemsList
          //by updating the state of renderCount which is assigned as ItemsList key value
          this.setState({
            itemName: "",
            numberOfTokensNeeded: 0,
            renderCount: this.state.renderCount + 1,
            isLoading: false,
          });
        } else {
          setToaster({ state: { error: true }, message: "Error: " + result.Message });
        }
      }
    };
    //handle errors
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({ isLoading: false });
        setToaster({
          state: { error: true },
          message: error.response ? error.message : "Unknown Error",
        });
        if (error.response && error.response.status && error.response.status === 401) {
          this.setState({ redirect: true });
        }
      }
    };
    addItem({
      url: "ItemService/Create",
      data: data,
      successCallback,
      errorCallback,
    });
  }
  handleBlurValidation = (e: any) => {
    let errors = Validator({ fields: e.target });
    if (errors.length === 0) {
      this.setState({ itemFormErrors: [] }, () => {
        resetValidationErrors(e.target);
      });
    } else {
      this.setState({ itemFormErrors: errors }, () => {
        showValidationErrors(this.state.itemFormErrors);
      });
    }
  };
  handleFocus = (e: any) => {
    this.setState({ itemFormErrors: [] }, () => {
      resetValidationErrors(e.target);
    });
  };
  handleSubmit = (e: any) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const itemName = this.state.itemName.trim();
    const tokenValue = this.state.numberOfTokensNeeded;
    let form = document.getElementById("addItemForm");
    let allFormElements = getAllFormElements({ element: form ? form : e });
    let errors = Validator({ fields: allFormElements });

    if (errors.length === 0) {
      this.postData({
        ChildId: this.props.ChildId,
        ItemName: itemName,
        NumberOfTokensNeeded: tokenValue,
      });
      this.setState({ itemName: "", numberOfTokensNeeded: 0, itemFormErrors: [] });
    } else {
      this.setState({ isLoading: false, itemFormErrors: errors }, () => {
        showValidationErrors(this.state.itemFormErrors);
      });
    }
  };
  handleTextChange = (e: any) => {
    let itemName = sanitizeInput(e.target.value, e.target.type);
    this.setState({ itemName: itemName });
  };
  handleTokenChange = (e: any) => {
    if (e.target.value < 0) {
      e.target.value = 0;
    }
    this.setState({ numberOfTokensNeeded: e.target.value });
  };
  componentDidMount() {}
  render() {
    const contextValue = {
      updateTokenValue: this.setTokenChangedValue,
    };
    return (
      <TokenContext.Provider value={contextValue}>
        <div>
          <div className="col-md-12">
            <h3 className="title">{this.props.title}</h3>
          </div>
          <hr></hr>
        </div>
        <div className="row">
          <div className="col-md-5">
            <div className="col-md-12">
              <SmallSpinner isLoading={this.state.isLoading} actionText="Loading" />
            </div>
            <ChildProfile
              key={"ChildProfile_" + this.state.renderCount}
              ChildId={this.props.ChildId}
            />
          </div>
          <div className="col-md-7 ml-auto mr-auto">
            <div className="card-form">
              <h5 className="card-title">Add Item</h5>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <SmallSpinner isLoading={this.state.isLoading} actionText="Please Wait" />
                  </div>
                </div>
              </div>
              <ValidationSummary Errors={this.state.itemFormErrors} />
              <p>Add new item and set it's points value</p>
              <form id="addItemForm" className="form-inline" onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <input
                    id="item"
                    className="form-control border-input"
                    type="text"
                    placeholder="Item name"
                    onChange={this.handleTextChange}
                    onBlur={this.handleBlurValidation}
                    onFocus={this.handleFocus}
                    value={this.state.itemName}
                    data-required="true"
                    data-validationname="Item"
                    data-id="item"
                  />
                  <input
                    id="points_value"
                    className="form-control border-input"
                    type="number"
                    placeholder="Points"
                    onChange={this.handleTokenChange}
                    value={this.state.numberOfTokensNeeded}
                    onBlur={this.handleBlurValidation}
                    onFocus={this.handleFocus}
                    data-required="true"
                    data-min="1"
                    data-validationname="Points"
                    data-id="item"
                  />
                  <button className="btn btn-default btn-round">Add</button>
                </div>
              </form>
            </div>
            <hr></hr>
            <ItemList
              key={"ItemList_" + this.state.renderCount}
              renderCount={this.state.renderCount}
              ChildId={this.props.ChildId}
            />
          </div>
        </div>
        <ToastContainer autoClose={2000} />
        {this.state.redirect ? <Redirect path="/family" /> : <></>}
      </TokenContext.Provider>
    );
  }
}

export { TokenContext };
export default ChildDetails;
