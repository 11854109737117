interface Props {
  fields: any;
}

export const sanitizeInput = (value: string, type: string): string => {
  let cleanString: string = "";
  switch (type) {
    case "text":
      cleanString = value.replace(/[|&;$%@"<>()+,]/g, "");
      break;
    case "email":
      cleanString = value.replace(/[|&;$%"<>()+,]/g, "");
      break;
    case "password":
      cleanString = value;
      break;
  }
  return cleanString;
};

export function Validator(props: Props): any {
  let errors = [] as any;

  //using data attributes on the input elements as opposed to the 'required' and 'min' attributes allows
  //us to circumnvent the built in browser html5 validation, which is not supported by all broswers
  const validateInput = (input: any): void => {
    if (input.attributes["data-required"] && input.attributes["data-required"].value === "true") {
      switch (input.type) {
        case "email":
          //do is required and email validation
          isRequired(input);
          validateEmail(input);
          break;
        case "number":
          //do is required and number validation
          isRequired(input);
          validateNumber(input);
          break;
        case "checkbox":
          //do is required and checkbox validation
          isRequired(input);
          validateCheckbox(input);
          break;
        default:
          //do just required validation
          isRequired(input);
          break;
      }
    } else {
      switch (input.type) {
        case "email":
          //do email validation
          validateEmail(input);
          break;
        case "number":
          //do number validation
          validateNumber(input);
          break;
      }
    }
  };

  const isRequired = (input: any): void => {
    if (input.value === null || input.value === undefined || input.value === "") {
      let id: any = input.attributes["data-id"]
        ? input.attributes["data-id"]
        : input.attributes["data-rowId"];
      let error = {
        fieldName: input.id,
        message: input.attributes["data-validationname"].value + " is required",
        id: id.value,
      };
      errors?.push(error);
    }
  };
  const validateEmail = (input: any): void => {
    if (input.value !== null && input.value !== undefined && input.value !== "") {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input.value) === false) {
        let error = {
          fieldName: input.id,
          message: "email address is invalid",
          id: input.attributes["data-id"].value,
        };
        errors?.push(error);
      }
    }
  };
  const validateNumber = (input: any): void => {
    if (
      input.value !== null &&
      input.value !== undefined &&
      input.value !== "" &&
      input.attributes["data-min"] &&
      input.attributes["data-min"].value !== ""
    ) {
      if (isNaN(input.value)) {
        let error = {
          fieldName: input.id,
          message: input.attributes["data-validationname"].value + " must be a number",
          id: input.attributes["data-id"].value,
        };
        errors?.push(error);
      } else if (input.attributes["data-min"] && input.attributes["data-min"].value !== "") {
        //do we have a value greater than the min value?
        let minValue = parseInt(input.attributes["data-min"].value);
        if (input.value < minValue) {
          let error = {
            fieldName: input.id,
            message:
              input.attributes["data-validationname"].value +
              " must have a value equalling at least " +
              minValue.toString(),
            id: input.attributes["data-id"].value,
          };
          errors?.push(error);
        }
      }
    }
  };
  const validateCheckbox = (input: any): void => {
    if ((input.value !== null && input.value !== undefined) || input.checked === false) {
      let error = {
        fieldName: input.id,
        message: input.attributes["data-validationname"].value + " is required",
        id: input.attributes["data-id"].value,
      };
      errors?.push(error);
    }
  };
  //text fields
  if (props.fields !== null && props.fields !== undefined) {
    if (
      props.fields.length !== null &&
      props.fields.length !== undefined &&
      props.fields.length > 0
    ) {
      props.fields.forEach((input: any) => {
        validateInput(input);
      });
    } else {
      validateInput(props.fields);
    }
  }

  return errors;
}
