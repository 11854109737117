interface Props {
  id: string;
  message: string;
  cancel: string;
  confirm: string;
  isVisible: boolean;
  onDelete: any;
}

export const SmallAlert = (props: Props): any => {
  if (props.isVisible) {
    return (
      <div
        className="modal fade"
        id={props.id}
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm ">
          <div className="modal-content">
            <div className="modal-header no-border-header">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body text-center">
              <h5>{props.message}</h5>
            </div>
            <div className="modal-footer">
              <div className="left-side">
                <button type="button" className="btn btn-default btn-link" data-dismiss="modal">
                  {props.cancel}
                </button>
              </div>
              <div className="divider"></div>
              <div className="right-side">
                <button
                  type="button"
                  className="btn btn-danger btn-link"
                  onClick={props.onDelete}
                  data-dismiss="modal"
                >
                  {props.confirm}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};
