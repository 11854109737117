import React from "react";
import { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { setToaster } from "../Toaster";
import { ToastContainer } from "react-toastify";
import { sanitizeInput, Validator } from "../Validation/Validator";
import {
  getAllFormElements,
  showValidationErrors,
  resetValidationErrors,
  Redirect,
} from "../Utilities";
import ValidationSummary from "../Validation/ValidationSummary";
import { ResetUserPassword } from "../Account/AccountUtil";

interface Props {
  Title: string;
  UserId: string | undefined;
  Code: string | undefined;
}

interface State {
  newPassword: string;
  confirmNewPassword: string;
  formErrors?: any;
  redirect?: boolean;
  isLoading: boolean;
}

const ResetPassword = (props: Props): any => {
  const [state, setState] = useState<State>({
    newPassword: "",
    confirmNewPassword: "",
    formErrors: [],
    isLoading: false,
  });

  useEffect((): void => {
    if (
      state.formErrors !== null &&
      state.formErrors !== undefined &&
      state.formErrors.length > 0
    ) {
      showValidationErrors(state.formErrors);
    }
  }, [state.formErrors]);

  const resetPassword = (data: any): void => {
    //process results from ajax call
    const successCallback = (result: any): void => {
      if (result && result.Success) {
        //do logic
        setToaster({
          state: { success: true },
          message: "Success: Account updated ",
        });
        setState({
          newPassword: data.newPassword,
          confirmNewPassword: data.newPassword,
          isLoading: false,
          formErrors: [],
          redirect: true,
        });
      } else if (result != null && result.Data !== null && result.Data !== undefined) {
        setToaster({
          state: { error: true },
          message: "Failed: Errors encountered",
        });
        let passwordErrors: any = [];
        result.Data.map((error: any) => {
          let valError = {
            fieldName: error.Code,
            message: error.Description,
            id: "",
          };
          passwordErrors.push(valError);
        });
        setState({
          newPassword: data.newPassword,
          confirmNewPassword: data.newPassword,
          isLoading: false,
          formErrors: passwordErrors,
        });
      } else {
        setToaster({
          state: { error: true },
          message: "Failed: Account not updated: " + result.Message,
        });
        setState({
          newPassword: data.newPassword,
          confirmNewPassword: data.newPassword,
          isLoading: false,
          formErrors: [],
        });
      }
    };
    //handle errors
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        setToaster({
          state: { error: true },
          message: error.response ? error.message : "Unknown Error",
        });
        setState({
          newPassword: data.newPassword,
          confirmNewPassword: data.newPassword,
          formErrors: [],
          isLoading: false,
        });
      }
    };

    ResetUserPassword({
      url: "Authentication/ResetPassword",
      data: data,
      successCallback,
      errorCallback,
    });
  };
  const handleBlurValidation = (e: any) => {
    let errors = Validator({ fields: e.target });
    if (errors.length === 0) {
      setState({
        newPassword: state.newPassword,
        confirmNewPassword: state.confirmNewPassword,
        formErrors: [],
        isLoading: false,
      });
      resetValidationErrors(e.target);
    } else {
      setState({
        newPassword: state.newPassword,
        confirmNewPassword: state.confirmNewPassword,
        formErrors: errors,
        isLoading: false,
      });
      showValidationErrors(state.formErrors);
    }
  };
  const handleFocus = (e: any) => {
    setState({
      newPassword: state.newPassword,
      confirmNewPassword: state.confirmNewPassword,
      formErrors: [],
      isLoading: false,
    });
    resetValidationErrors(e.target);
  };
  const handPasswordChange = (e: any) => {
    let password = sanitizeInput(e.target.value, e.target.type);
    setState({
      newPassword: password,
      confirmNewPassword: state.confirmNewPassword,
      isLoading: false,
    });
  };
  const handConfirmNewPasswordChange = (e: any) => {
    let password = sanitizeInput(e.target.value, e.target.type);
    setState({
      newPassword: state.newPassword,
      confirmNewPassword: password,
      isLoading: false,
    });
  };
  const handleSubmit = (e: any): any => {
    e.preventDefault();
    setState({
      newPassword: state.newPassword,
      confirmNewPassword: state.confirmNewPassword,
      formErrors: [],
      isLoading: true,
    });
    const password = state.newPassword ? state.newPassword.trim() : "";
    const confirmPassword = state.confirmNewPassword ? state.confirmNewPassword.trim() : "";
    let form = document.getElementById("passwordreset");
    let allFormElements = getAllFormElements({ element: form ? form : e });
    let errors = Validator({ fields: allFormElements });

    if (password !== confirmPassword) {
      let error = { fieldName: "password", message: "Password and Confirm Password do not match" };
      errors.push(error);
    }

    if (errors.length === 0) {
      let data: any = {
        Id: props.UserId,
        Code: props.Code,
        NewPassword: password,
      };
      resetPassword(data);
    } else {
      setState({
        newPassword: state.newPassword,
        confirmNewPassword: state.confirmNewPassword,
        formErrors: errors,
        isLoading: false,
      });
    }
  };

  return (
    <>
      <div>
        <div className="col-md-12">
          <h3 className="title">{props.Title}</h3>
        </div>
        <hr></hr>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <div className="card-info">
              <h6>Password Policy</h6>
              <p>
                Passwords must be at least 6 characters.
                <br /> Have at least one non alphanumeric character.
                <br /> Have at least one lowercase character.
                <br />
                Have at least one uppercase character.
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card-form">
              <h5 className="card-title">{props.Title}</h5>
              <ValidationSummary Errors={state.formErrors} />
              <form id="passwordreset" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="newPassword" className="label-dark">
                    New Password
                  </label>
                  <input
                    id="newPassword"
                    className="form-control border-input"
                    type="password"
                    value={state.newPassword}
                    onChange={handPasswordChange}
                    onBlur={handleBlurValidation}
                    onFocus={handleFocus}
                    placeholder="New Password"
                    data-required="true"
                    data-validationname="New Password"
                    data-id="newPassword"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="confirmNewPassword" className="label-dark">
                    Confirm New Password
                  </label>
                  <input
                    id="confirmNewPassword"
                    className="form-control border-input"
                    type="password"
                    value={state.confirmNewPassword}
                    onChange={handConfirmNewPasswordChange}
                    onBlur={handleBlurValidation}
                    onFocus={handleFocus}
                    placeholder="Confirm New Password"
                    data-required="true"
                    data-validationname="Confirm New Password"
                    data-id="confirmNewPassword"
                  />
                </div>
                <div className="card-footer">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <button
                          className="btn btn-default btn-round"
                          data-id="confirmNewPasswordBtn"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <ToastContainer autoClose={2000} />
              {state.redirect ? <Redirect path="/resetpasswordconfrimed" isLogout={true} /> : <></>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
