import React from "react";
import ResetEmailSent from "../components/Account/ResetEmailSent";

function ResetEmailSentRoute(): any {
  return (
    <div id="resetemailsent">
      <ResetEmailSent Title="Password Reset Email" />
    </div>
  );
}

export default ResetEmailSentRoute;
