import Register from "../components/Account/Register";

function RegisterRoute(): any {
  return (
    <div id="register">
      <Register title="Register" />
    </div>
  );
}

export default RegisterRoute;
