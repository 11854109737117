import React from "react";
import { Link } from "react-router-dom";

interface Props {
  Title: string;
}

const ResetPasswordConfirmed = (props: Props): any => {
  return (
    <>
      <div>
        <div className="col-md-12">
          <h3 className="title">{props.Title}</h3>
        </div>
        <hr></hr>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h3>Password has been changed!</h3>
            <p>
              Please &nbsp;
              <Link to={`../login`}>log in</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordConfirmed;
