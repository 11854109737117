import React from "react";
import SmallSpinner from "../SmallSpinner";
import { Link } from "react-router-dom";
import {
  faChild,
  faChildReaching,
  faEllipsis,
  faMinus,
  faPlus,
  faStar,
  faCoins,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setToaster } from "../Toaster";
import { ToastContainer } from "react-toastify";
import { updateTokenValue } from "../Family/Family";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { AxiosError } from "axios";
import { Redirect } from "../Utilities";

interface Props {
  name: string;
  TokensTotal: number;
  ChildId: string;
  userCulture?: any;
  Items?: number;
  AvailableItems?: number;
  ChosenItems?: number;
  Balance?: string;
  CurrencySymbol?: string;
}

interface State {
  TokensTotal: number;
  redirect: boolean;
  newItemAvailable: boolean;
  renderCount: number;
  confettiPieces: number;
  isLoading: boolean;
  Balance: string;
}

class Card extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      TokensTotal: this.props.TokensTotal,
      redirect: false,
      newItemAvailable: false,
      renderCount: 0,
      confettiPieces: 0,
      isLoading: false,
      Balance: this.props.Balance ? this.props.Balance : "",
    };
    this.handlePlusClick = this.handlePlusClick.bind(this);
    this.handleMinusClick = this.handleMinusClick.bind(this);
  }

  avatarColors: any = [
    "childAvatar-red",
    "childAvatar-blue",
    "childAvatar-orange",
    "childAvatar-purple",
    "childAvatar-lightblue",
    "childAvatar-lightpurple",
    "childAvatar-lightred",
    "childAvatar-yellow",
    "childAvatar-green",
  ];
  updateToken = () => {
    //process results from ajax call
    const successCallback = (result: any): void => {
      if (result && result !== undefined) {
        //do logic
        if (result.Success === true) {
          if (result.Data !== null) {
            this.setState({ Balance: result.Data.Balance });
          }
          setToaster({
            state: { success: true },
            message: "Success: Points updated for " + this.props.name,
          });
        } else {
          setToaster({ state: { error: true }, message: "Error: " + result.Message });
        }
        this.setState({ isLoading: false });
      }
    };
    //handle errors
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        setToaster({
          state: { error: true },
          message: error.response ? error.message : "Unknown Error",
        });
        if (error.response && error.response.status && error.response.status === 401) {
          this.setState({ redirect: true });
        }
        this.setState({ isLoading: false });
      }
    };
    updateTokenValue({
      url: "ChildService/UpdateTokenValue",
      data: { childId: this.props.ChildId, tokensTotal: this.state.TokensTotal },
      successCallback,
      errorCallback,
    });
  };
  handlePlusClick = (e: any) => {
    e.preventDefault();
    this.setState({ isLoading: true }, () => {
      if (this.state.TokensTotal >= 0) {
        this.setState(
          {
            TokensTotal: this.state.TokensTotal + 1,
            renderCount: this.state.renderCount + 1,
          },
          () => {
            this.updateToken();
            if (
              document.activeElement !== null &&
              document.activeElement !== undefined &&
              document.activeElement instanceof HTMLElement
            ) {
              document.activeElement.blur();
            }
          }
        );
      }
    });
  };
  handleMinusClick = (e: any) => {
    e.preventDefault();
    this.setState({ isLoading: true }, () => {
      if (this.state.TokensTotal > 0) {
        this.setState(
          {
            TokensTotal: this.state.TokensTotal - 1,
            renderCount: this.state.renderCount + 1,
          },
          () => {
            this.updateToken();
            if (
              document.activeElement !== null &&
              document.activeElement !== undefined &&
              document.activeElement instanceof HTMLElement
            ) {
              document.activeElement.blur();
            }
          }
        );
      }
    });
  };
  render() {
    return (
      <div className="col-md-6">
        <div className="card card-profile">
          <div className="card-avatar border-white">
            {this.props.AvailableItems == 0 ? (
              <span>
                <FontAwesomeIcon
                  icon={faChild}
                  size="4x"
                  className={this.avatarColors[(Math.random() * this.avatarColors.length) | 0]}
                />
              </span>
            ) : (
              <span>
                <FontAwesomeIcon
                  icon={faChildReaching}
                  size="4x"
                  className={this.avatarColors[(Math.random() * this.avatarColors.length) | 0]}
                />
              </span>
            )}
          </div>
          <div className="card-bod">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  {/*<CongfettiPack
                    key={"confetti_" + this.state.renderCount}
                    NumberOfPieces={this.state.confettiPieces}
                    TimerSeconds={10}
    />*/}
                  <h4 className="card-title">{this.props.name}</h4>
                  <SmallSpinner isLoading={this.state.isLoading} />
                  <h6 className="card-category">
                    <span>
                      <FontAwesomeIcon icon={faStar} className="tokens" />
                    </span>
                    <span className="tokenSpan">Points: {this.state.TokensTotal}</span>
                  </h6>
                </div>
              </div>
              {this.state.Balance !== null &&
                this.state.Balance !== undefined &&
                this.state.Balance !== "0.00" && (
                  <div className="row">
                    <div className="col-sm-12">
                      <h6 className="card-category">
                        <span>
                          <FontAwesomeIcon icon={faCoins} className="tokens" />
                        </span>
                        <span className="tokenSpan">
                          Earned: {this.props.CurrencySymbol + this.state.Balance.toString()}
                        </span>
                      </h6>
                    </div>
                  </div>
                )}
              <div className="row">
                <div className="col-sm-12 card-description">
                  <span className="items-default">
                    <strong>Items: {this.props.Items}</strong>
                  </span>
                  {this.props.AvailableItems ? (
                    this.props.AvailableItems > 0 && (
                      <span className="available-items-green">
                        <strong>Available: {this.props.AvailableItems}</strong>
                      </span>
                    )
                  ) : (
                    <span className="available-items-red">
                      <strong>Available: {this.props.AvailableItems}</strong>
                    </span>
                  )}
                  {this.props.ChosenItems
                    ? this.props.ChosenItems > 0 && (
                        <span className="available-items-green">
                          <strong>Chosen: {this.props.ChosenItems}</strong>
                        </span>
                      )
                    : null}
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <div className="card-footer text-center">
                    <button
                      id={"addtoken_" + this.props.ChildId}
                      onClick={this.handlePlusClick}
                      className="btn btn-just-icon btn-outline-plus"
                      data-tooltip-content="Add Point"
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                    <button
                      id={"removetoken_" + this.props.ChildId}
                      onClick={this.handleMinusClick}
                      className="btn btn-just-icon btn-outline-minus "
                      data-tooltip-content="Remove Point"
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </button>
                    <Link
                      id={"items_" + this.props.ChildId}
                      to={`../childdetails/` + this.props.ChildId}
                      className="btn btn-just-icon btn-outline-default "
                      data-tooltip-content="Profile &amp; Items"
                    >
                      <FontAwesomeIcon icon={faEllipsis} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReactTooltip
          anchorId={"addtoken_" + this.props.ChildId}
          place="bottom"
          variant="dark"
          content="Add Point"
        />
        <ReactTooltip
          anchorId={"removetoken_" + this.props.ChildId}
          place="bottom"
          variant="dark"
          content="Remove Point"
        />
        <ReactTooltip
          anchorId={"items_" + this.props.ChildId}
          place="bottom"
          variant="dark"
          content="Profile &amp; Items"
        />

        <ToastContainer autoClose={2000} />
        {this.state.redirect ? <Redirect path="/login" isLogout={true} /> : <></>}
      </div>
    );
  }
}

export default Card;
