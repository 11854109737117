import React from "react";
import ResetPasswordConfirmed from "../components/Account/ResetPasswordConfirmed";

function ResetPasswordConfirmedRoute(): any {
  return (
    <div id="resetpasswordconfrimed">
      <ResetPasswordConfirmed Title="Password Change Confirmed" />
    </div>
  );
}

export default ResetPasswordConfirmedRoute;
