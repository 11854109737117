import React from "react";
import SmallSpinner from "../SmallSpinner";
import ValidationSummary from "../Validation/ValidationSummary";
import { sanitizeInput, Validator } from "../Validation/Validator";
import { getAllFormElements, showValidationErrors, resetValidationErrors } from "../Utilities";
import { Redirect } from "../Utilities";
import { AxiosError } from "axios";
import { setToaster } from "../Toaster";
import { ToastContainer } from "react-toastify";
import { addFamilyMember } from "../Family/Family";
import { DataGrid } from "../Grid/DataGrid";
import { userContext } from "../Menu/MenuPicker";

interface Props {
  title: string;
}

interface State {
  data: any;
  isLoading: boolean;
  name: string;
  email: string;
  renderCount: number;
  memberFormErrors: any;
  noData: boolean;
  redirect: boolean;
  user: any;
}

class FamilyMembers extends React.Component<Props, State> {
  static contextType = userContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      isLoading: false,
      name: "",
      email: "",
      renderCount: 0,
      memberFormErrors: [],
      noData: true,
      redirect: false,
      user: {},
    };
    this.handleBlurValidation = this.handleBlurValidation.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleMemberDeleted = this.handleMemberDeleted.bind(this);
  }
  postData(data: any): void {
    //process results from ajax call
    const successCallback = (result: any): void => {
      if (result && result !== undefined) {
        //do logic
        if (result.Success === true) {
          setToaster({
            state: { success: true },
            message: "Success: Invite Sent",
          });

          //force a rerender of ItemsList
          //by updating the state of renderCount which is assigned as ItemsList key value
          this.setState({
            name: "",
            email: "",
            renderCount: this.state.renderCount + 1,
            isLoading: false,
          });
        } else {
          setToaster({ state: { error: true }, message: "Error: " + result.Message });
          this.setState({ isLoading: false });
        }
      }
    };
    //handle errors
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({ isLoading: false });
        this.setState({ isLoading: false }, () => {
          setToaster({
            state: { error: true },
            message: error.response ? error.message : "Unknown Error",
          });
        });
        if (error.response && error.response.status && error.response.status === 401) {
        }
      }
    };
    addFamilyMember({
      url: "Authentication/Register",
      data: data,
      successCallback,
      errorCallback,
    });
  }
  handleBlurValidation = (e: any) => {
    let errors = Validator({ fields: e.target });
    if (errors.length === 0) {
      this.setState({ memberFormErrors: [] }, () => {
        resetValidationErrors(e.target);
      });
    } else {
      this.setState({ memberFormErrors: errors }, () => {
        showValidationErrors(this.state.memberFormErrors);
      });
    }
  };
  handleFocus = (e: any) => {
    this.setState({ memberFormErrors: [] }, () => {
      resetValidationErrors(e.target);
    });
  };
  handleSubmit(e: any) {
    e.preventDefault();
    this.setState({ isLoading: true });
    const email = this.state.email.trim();
    const name = this.state.name.trim();
    let form = document.getElementById("addFamilyMemberForm");
    let allFormElements = getAllFormElements({ element: form ? form : e });
    let errors = Validator({ fields: allFormElements });
    if (errors.length === 0 && this.state.user.FamilyId !== "") {
      this.postData({
        FamilyId: this.state.user.FamilyId,
        Name: name,
        Username: email,
        IsFamilyAdmin: false,
      });
      this.setState({ email: "", name: "", memberFormErrors: [] });
    } else {
      this.setState({ isLoading: false, memberFormErrors: errors }, () => {
        showValidationErrors(this.state.memberFormErrors);
      });
    }
  }
  handleNameChange(e: any) {
    let name = sanitizeInput(e.target.value, e.target.type);
    this.setState({ name: name });
  }
  handleEmailChange(e: any) {
    let email = sanitizeInput(e.target.value, e.target.type);
    this.setState({ email: email });
  }
  handleMemberDeleted() {
    this.setState({
      renderCount: this.state.renderCount + 1,
    });
  }
  buildColumns(): any {
    const columnHeaders = [
      {
        DataPropertyName: "Id",
        IsIdColumn: true,
        ShowColumn: false,
      },
      {
        DataPropertyName: "Name",
        ColumnAliasName: "Name",
        InputType: "text",
        IsRequired: true,
        ShowColumn: true,
        CanEdit: true,
      },
      {
        DataPropertyName: "UserName",
        ColumnAliasName: "Email",
        InputType: "email",
        ShowColumn: true,
      },
      {
        DataPropertyName: "EmailConfirmed",
        ColumnAliasName: "Email Confirmed",
        InputType: "text",
        ShowColumn: true,
        BooleanText: { TrueState: "Confirmed", FalseState: "Pending" },
      },
      {
        DataPropertyName: "FamilyAdminUserName",
        ShowColumn: false,
      },
      {
        DataPropertyName: "",
        ColumnAliasName: "Actions",
        ShowColumn: true,
        IsActionsColumn: true,
      },
    ] as any;

    return columnHeaders;
  }
  componentDidMount(): void {
    let context: any = this.context;
    this.setState({ user: context.user });
  }
  render() {
    return (
      <>
        <div>
          <div className="col-md-12">
            <h3 className="title">{this.props.title}</h3>
          </div>
          <hr></hr>
        </div>
        <div className="row">
          <div className="col-md-12 ml-auto mr-auto">
            <div className="card-form">
              <h5 className="card-title">Invite Family Member</h5>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <SmallSpinner isLoading={this.state.isLoading} actionText="Sending Invite" />
                  </div>
                </div>
              </div>
              <ValidationSummary Errors={this.state.memberFormErrors} />
              <p>Family members can manage children's points and items </p>
              <form id="addFamilyMemberForm" className="form-inline" onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <input
                    id="name"
                    className="form-control border-input"
                    type="text"
                    placeholder="First Name"
                    onChange={this.handleNameChange}
                    onBlur={this.handleBlurValidation}
                    onFocus={this.handleFocus}
                    value={this.state.name}
                    data-required="true"
                    data-validationname="Name"
                    data-id="Name"
                  />
                  <input
                    id="email"
                    className="form-control border-input"
                    type="email"
                    placeholder="Email"
                    onChange={this.handleEmailChange}
                    onBlur={this.handleBlurValidation}
                    onFocus={this.handleFocus}
                    value={this.state.email}
                    data-required="true"
                    data-validationname="Email"
                    data-id="email"
                  />
                  <button className="btn btn-default btn-round">Invite</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <DataGrid
              key={"dataGrid_" + this.state.renderCount}
              ColumnArray={this.buildColumns()}
              SelectAPI="FamilyService/GetFamilyMembers"
              UpdateAPI="FamilyService/UpdateFamilyMember"
              DeleteAPI="FamilyService/DeleteFamilyMember"
              MaxNumberOfRowsPerPage={50}
              GridName="familyMembers"
            />
          </div>
        </div>
        <ToastContainer autoClose={2000} />
        {this.state.redirect ? <Redirect path="/login" isLogout={true} /> : <></>}
      </>
    );
  }
}

export default FamilyMembers;
