import React from "react";
import RegisterConfirmed from "../components/Account/RegisterConfirmed";

function RegisterConfirmedRoute(): any {
  return (
    <div id="registerconfirmed">
      <RegisterConfirmed Title="Registration Confirmed" />
    </div>
  );
}

export default RegisterConfirmedRoute;
