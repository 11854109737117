import React from "react";
import {
  faGift,
  faCheck,
  faPenToSquare,
  faTrash,
  faArrowDown,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Validator, sanitizeInput } from "../Validation/Validator";
import { getAllFormElements, showValidationErrors, resetValidationErrors } from "../Utilities";
import Checkbox from "../Checkbox";
import ValidationSummary from "../Validation/ValidationSummary";

interface Props {
  ItemName: string;
  NumberOfTokensNeeded: number;
  ItemId: string;
  TokensTotal: number;
  targetDate: any;
  onDeletingItem: any;
  onUpdating?: any;
  onCompleting?: any;
  IsChosen: boolean;
}

interface State {
  isDisabled: boolean;
  remainingTokensNeeded: number;
  itemName: string;
  numberOfTokensNeeded: number;
  isDeleting: boolean;
  isChosen: boolean;
  formErrors: any;
}

class Item extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isDisabled: true,
      remainingTokensNeeded: this.props.NumberOfTokensNeeded - +this.props.TokensTotal,
      itemName: this.props.ItemName,
      numberOfTokensNeeded: this.props.NumberOfTokensNeeded,
      isDeleting: false,
      isChosen: this.props.IsChosen,
      formErrors: [],
    };
    this.handleBlurValidation = this.handleBlurValidation.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleItemNameChange = this.handleItemNameChange.bind(this);
    this.handleTokenChange = this.handleTokenChange.bind(this);
    this.handleItemDelete = this.handleItemDelete.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleItemComplete = this.handleItemComplete.bind(this);
  }
  updateDisabled = () => {
    if (this.props.NumberOfTokensNeeded <= +this.props.TokensTotal || this.props.IsChosen) {
      this.setState({
        isDisabled: false,
      });
    } else {
      this.setState({
        isDisabled: true,
      });
    }
  };
  handleBlurValidation = (e: any) => {
    let errors = Validator({ fields: e.target });
    if (errors.length === 0) {
      this.setState({ formErrors: [] }, () => {
        resetValidationErrors(e.target);
      });
    } else {
      this.setState({ formErrors: errors }, () => {
        showValidationErrors(this.state.formErrors);
      });
    }
  };
  handleFocus = (e: any) => {
    this.setState({ formErrors: [] }, () => {
      resetValidationErrors(e.target);
    });
  };
  handleItemNameChange = (e: any) => {
    let itemdName = sanitizeInput(e.target.value, e.target.type);
    this.setState({ itemName: itemdName });
  };
  handleTokenChange = (e: any) => {
    if (e.target.value < 0) {
      e.target.value = 0;
    }
    this.setState({ numberOfTokensNeeded: e.target.value });
  };
  handleCheckBoxChange = (e: any) => {
    if (e !== null && e !== undefined) {
      this.setState({ isChosen: e.target.checked }, () => {
        if (this.state.itemName.trim() !== "" && this.state.numberOfTokensNeeded > 0) {
          this.handleUpdate(e);
        }
      });
    }
  };
  handleUpdate = (e: any) => {
    e.preventDefault();
    const itemName = this.state.itemName.trim();
    const tokensNeeded = this.state.numberOfTokensNeeded;
    const isChosen = this.state.isChosen;
    let form = document.getElementById("itemsForm");
    let allFormElements = getAllFormElements({ element: form ? form : e });
    let errors = Validator({ fields: allFormElements });

    if (errors.length === 0) {
      this.props.onUpdating({
        ItemId: this.props.ItemId,
        ItemName: itemName,
        NumberOfTokensNeeded: tokensNeeded,
        IsChosen: isChosen,
      });
      this.setState({ formErrors: [] });
    } else {
      let itemErrors: any = [];
      errors.map((item: any) => {
        if (e.currentTarget.attributes["data-id"].value === item.id) {
          itemErrors.push(item);
        }
      });
      //show notification
      this.setState({ formErrors: itemErrors }, () => {
        showValidationErrors(itemErrors);
      });
    }
  };
  handleItemDelete = (e: any) => {
    e.preventDefault();
    this.props.onDeletingItem({ ItemId: this.props.ItemId });
  };
  handleItemComplete = (e: any) => {
    e.preventDefault();
    this.props.onCompleting({ ItemId: this.props.ItemId });
  };
  componentDidMount() {
    this.updateDisabled();
  }
  componentWillUnmount() {
    this.setState({
      isDisabled: this.props.IsChosen ? false : true,
      remainingTokensNeeded: this.props.NumberOfTokensNeeded - +this.props.TokensTotal,
      itemName: this.props.ItemName,
      numberOfTokensNeeded: this.props.NumberOfTokensNeeded,
      isDeleting: false,
      isChosen: this.props.IsChosen,
    });
  }
  render() {
    return (
      <div className="card no-transition">
        <div
          className="card-header card-collapse"
          role="tab"
          id={"headingOne_" + this.props.ItemId}
        >
          <h5 className="mb-0 panel-title">
            <a
              className="collapsed"
              data-toggle="collapse"
              data-parent="#accordion"
              href={"#collapseOne_" + this.props.ItemId}
              aria-expanded="false"
              aria-controls={"collapseOne_" + this.props.ItemId}
            >
              {this.state.itemName} &nbsp;
              {this.state.isChosen ? (
                <FontAwesomeIcon icon={faCheck} className="check" size="1x" />
              ) : !this.state.isDisabled ? (
                <span>
                  <small>- Available</small>
                </span>
              ) : (
                <></>
              )}
              <i>
                <FontAwesomeIcon icon={faArrowDown} size="1x" />
              </i>
            </a>
          </h5>
        </div>
        <div
          id={"collapseOne_" + this.props.ItemId}
          className="collapse"
          role="tabpanel"
          aria-labelledby={"headingOne_" + this.props.ItemId}
        >
          <div className="card-body">
            <div className="container">
              <ValidationSummary Errors={this.state.formErrors} />
              <div className="row">
                <div className="col-sm-1">
                  <FontAwesomeIcon icon={faGift} className="gift-medium" size="2x" />
                </div>
                <div className="col-sm">
                  <div className="form-group">
                    <label htmlFor={"itemName_" + this.props.ItemId} className="label-dark">
                      Item name
                    </label>
                    <input
                      id={"item_name-" + this.props.ItemId}
                      className="form-control border-input"
                      type="text"
                      placeholder="Item name"
                      onChange={this.handleItemNameChange}
                      onBlur={this.handleBlurValidation}
                      onFocus={this.handleFocus}
                      value={this.state.itemName}
                      data-required="true"
                      data-validationname="Item Name"
                      data-id={this.props.ItemId}
                    />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label htmlFor={"tokenValue_" + this.props.ItemId} className="label-dark">
                      Points value
                    </label>
                    <div className="input-group">
                      <input
                        id={"points_value-" + this.props.ItemId}
                        className="form-control border-input"
                        type="number"
                        placeholder="Points"
                        onChange={this.handleTokenChange}
                        onBlur={this.handleBlurValidation}
                        onFocus={this.handleFocus}
                        value={this.state.numberOfTokensNeeded}
                        data-required="true"
                        data-min="1"
                        data-validationname="Point Value"
                        data-id={this.props.ItemId}
                      />
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faStar} className="tokens" />
                      </span>
                    </div>
                  </div>
                </div>
                {!this.state.isDisabled ? (
                  <div className="col-sm-auto">
                    <div className="form-group">
                      <Checkbox
                        id={"chosen_" + this.props.ItemId}
                        label="Choose"
                        checked={this.state.isChosen}
                        onSelecting={this.handleCheckBoxChange}
                        datarequired={false}
                        labelClass="label-dark"
                        data-validationname="Item Checkbox"
                        data-id={this.props.ItemId}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <hr></hr>
            <div className="container">
              <div className="row">
                <div className="card-footer-profile">
                  <button
                    type="button"
                    id={"edit_" + this.props.ItemId}
                    className="btn btn-just-icon btn-outline-edit"
                    data-tooltip-content="Update Item"
                    onClick={this.handleUpdate}
                    data-id={this.props.ItemId}
                  >
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </button>
                  <button
                    type="button"
                    id={"delete_" + this.props.ItemId}
                    onClick={this.handleItemDelete}
                    className="btn btn-just-icon btn-outline-minus"
                    data-tooltip-content="Delete item"
                    data-id={this.props.ItemId}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                  {this.state.isChosen && (
                    <button
                      type="button"
                      id={"complete_" + this.props.ItemId}
                      onClick={this.handleItemComplete}
                      className="btn btn-just-icon btn-outline-plus"
                      data-tooltip-content="Complete item"
                      data-id={this.props.ItemId}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReactTooltip
          anchorId={"edit_" + this.props.ItemId}
          place="left"
          variant="dark"
          content="Update Name"
        />
        <ReactTooltip
          anchorId={"delete_" + this.props.ItemId}
          place="top"
          variant="dark"
          content={"Remove " + this.state.itemName}
        />
        <ReactTooltip
          anchorId={"complete_" + this.props.ItemId}
          place="right"
          variant="dark"
          content={"Complete " + this.state.itemName}
        />
      </div>
    );
  }
}

export default Item;
