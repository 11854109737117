import React from "react";
import SmallSpinner from "../SmallSpinner";
import { useState, useEffect } from "react";
import { AxiosError } from "axios";
import { setToaster } from "../Toaster";
import { ToastContainer } from "react-toastify";
import { sanitizeInput, Validator } from "../Validation/Validator";
import {
  getAllFormElements,
  showValidationErrors,
  resetValidationErrors,
  Redirect,
} from "../Utilities";
import { CheckEmailAddress, SendPasswordResetEmail } from "../Account/AccountUtil";
import ValidationSummary from "../Validation/ValidationSummary";

interface Props {
  Title: string;
}

interface State {
  email: string;
  formErrors?: any;
  emailExists?: boolean;
  redirect?: boolean;
  isLoading?: boolean;
}

const ForgotPasswordEmail = (props: Props): any => {
  const [state, setState] = useState<State>({ email: "", formErrors: [] });

  useEffect((): void => {
    if (
      state.formErrors !== null &&
      state.formErrors !== undefined &&
      state.formErrors.length > 0
    ) {
      showValidationErrors(state.formErrors);
    }
  }, [state.formErrors]);

  const checkEmailExists = (email: string): void => {
    //process results from ajax call
    const successCallback = (result: any): void => {
      if (result && result !== undefined) {
        //do logic
        if (result.Success === true) {
          setState({ email: email, formErrors: [], emailExists: true, isLoading: false });
          sendResetEmail(email);
        } else {
          let errors = [{ fieldName: "email", message: "email address does not exist" }];
          setState({ email: email, formErrors: errors, emailExists: false, isLoading: false });
        }
      }
    };
    //handle errors
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        setToaster({
          state: { error: true },
          message: error.response ? error.message : "Unknown Error",
        });
        setState({ email: email, formErrors: [], emailExists: false, isLoading: false });
      }
    };
    if (email !== null && email !== undefined) {
      let data: any = { Username: email };
      CheckEmailAddress({
        url: "Authentication/CheckEmail",
        data: data,
        successCallback,
        errorCallback,
      });
    }
  };
  const sendResetEmail = (email: string): void => {
    //process results from ajax call
    const successCallback = (result: any): void => {
      if (result && result !== undefined) {
        //do logic
        if (result.Success === true) {
          setToaster({
            state: { success: true },
            message: "Success: Email Sent",
          });
          setState({
            email: email,
            formErrors: [],
            emailExists: true,
            isLoading: false,
            redirect: true,
          });
        } else {
          setToaster({
            state: { error: true },
            message: "Failed: " + result.Message,
          });
          setState({ email: email, formErrors: [], emailExists: false, isLoading: false });
        }
      }
    };
    //handle errors
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        setToaster({
          state: { error: true },
          message: error.response ? error.message : "Unknown Error",
        });
        setState({ email: email, formErrors: [], emailExists: false, isLoading: false });
      }
    };
    if (email !== null && email !== undefined) {
      let data: any = { Username: email };
      SendPasswordResetEmail({
        url: "Authentication/SendPasswordResetEmail",
        data: data,
        successCallback,
        errorCallback,
      });
    }
  };
  const handleBlurValidation = (e: any) => {
    let errors = Validator({ fields: e.target });
    if (errors.length === 0) {
      setState({ email: state.email, formErrors: [] });
      resetValidationErrors(e.target);
    } else {
      setState({ email: state.email, formErrors: errors });
      showValidationErrors(state.formErrors);
    }
  };
  const handleFocus = (e: any) => {
    setState({ email: state.email, formErrors: [] });
    resetValidationErrors(e.target);
  };
  const handleEmailChange = (e: any) => {
    let email = sanitizeInput(e.target.value, e.target.type);
    setState({ email: email, emailExists: false });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setState({ email: state.email, formErrors: [], isLoading: true });
    const email = state.email.trim();
    let form = document.getElementById("passwordreset");
    let allFormElements = getAllFormElements({ element: form ? form : e });
    let errors = Validator({ fields: allFormElements });

    if (errors.length === 0) {
      //confirm email exists
      checkEmailExists(email);
    } else {
      setState({ email: email, formErrors: errors, isLoading: false });
    }
  };

  return (
    <>
      <div>
        <div className="col-md-12">
          <h3 className="title">{props.Title}</h3>
        </div>
        <hr></hr>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <div className="card-form">
              <h5 className="card-title">{props.Title}</h5>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <SmallSpinner isLoading={state.isLoading} actionText="Sending Email" />
                  </div>
                </div>
              </div>
              <ValidationSummary Errors={state.formErrors} />
              <form id="passwordreset" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="email" className="label-dark">
                    Email Address
                  </label>
                  <input
                    id="email"
                    className="form-control border-input"
                    type="email"
                    value={state.email}
                    onChange={handleEmailChange}
                    onBlur={handleBlurValidation}
                    onFocus={handleFocus}
                    placeholder="Email Address"
                    data-required="true"
                    data-validationname="Email Address"
                    data-id="email"
                  />
                </div>
                <div className="card-footer">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <button className="btn btn-default btn-round" data-id="emailBtn">
                          Send Reset Password Link
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <ToastContainer autoClose={2000} />
              {state.redirect ? <Redirect path="/resetemailsent" isLogout={true} /> : <></>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordEmail;
