import React from "react";

interface Props {
  Message: any;
}
class ValidationError extends React.Component<Props> {
  render() {
    return <li className="list-group-validation-item">{this.props.Message}</li>;
  }
}

export default ValidationError;
