import React from "react";
import { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { setToaster } from "../Toaster";
import { ToastContainer } from "react-toastify";
import { ConfirmEmailAddress } from "../Account/AccountUtil";

interface Props {
  Title: string;
  UserId: string | undefined;
  Code: string | undefined;
}

const ConfirmEmail = (props: Props) => {
  const [emailConfirmed, setemailConfirmed] = useState(false);
  useEffect(() => {
    checkEmail();
  }, []);
  const checkEmail = (): void => {
    //process results from ajax call
    const successCallback = (result: any): void => {
      if (result && result !== undefined) {
        //do logic
        if (result.Success === true) {
          setToaster({
            state: { success: true },
            message: "Success: Email Confirmed",
          });
          setemailConfirmed(true);
        } else {
          setToaster({ state: { error: true }, message: "Error: " + result.Message });
          setemailConfirmed(false);
        }
      }
    };
    //handle errors
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        setToaster({
          state: { error: true },
          message: error.response ? error.message : "Unknown Error",
        });
        setemailConfirmed(false);
      }
    };
    if (
      props.UserId !== null &&
      props.UserId !== undefined &&
      props.Code !== null &&
      props.Code !== undefined &&
      !emailConfirmed
    ) {
      let data: any = { Id: props.UserId, Code: props.Code };
      ConfirmEmailAddress({
        url: "Authentication/ConfirmEmail",
        data: data,
        successCallback,
        errorCallback,
      });
    }
  };
  return (
    <>
      <div>
        <div className="col-md-12">
          <h3 className="title">{props.Title}</h3>
        </div>
        <hr></hr>
      </div>
      <div className="container">
        <div className="row">
          {emailConfirmed && (
            <div className="col-sm-12">
              <h3>Email address Confirmed!</h3>
              <p>
                Please &nbsp;
                <Link to={`../login`}>log in</Link>
              </p>
            </div>
          )}
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </>
  );
};

export default ConfirmEmail;
