import React from "react";
import SmallSpinner from "./../components/SmallSpinner";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import ChildDetails from "../components/Family/ChildDetails";
import TokenStatus from "../components/Account/TokenStatus";
import { userContext } from "../components/Menu/MenuPicker";
import { Redirect } from "../components/Utilities";
import { GetTokenFromLocalStorage } from "./../components/Account/AuthenticationContext";

function ChildDetailsRoute(): any {
  let { childId } = useParams();
  const { user } = useContext(userContext) as any;

  let token: string | null = GetTokenFromLocalStorage();
  let haveToken: boolean = token === null || token === undefined || token === "" ? false : true;

  useEffect(() => {
    if (user !== null && user !== undefined) {
    }
  }, [user]);

  if (user === null || user === undefined || (Object.keys(user).length === 0 && haveToken)) {
    return (
      <>
        <div id="childdetails">
          <SmallSpinner isLoading={true} />
        </div>
      </>
    );
  } else if (!haveToken) {
    return <Redirect path="/login" isLogout={true} />;
  } else {
    return (
      <>
        <TokenStatus />
        <div id="childdetails">
          <ChildDetails title="Profile &amp; Items" ChildId={childId ? childId : ""} />
        </div>
      </>
    );
  }
}

export default ChildDetailsRoute;
