import React from "react";
import ForgotPasswordEmail from "../components/Account/ForgotPasswordEmail";

function ForgotPasswordEmailRoute(): any {
  return (
    <div id="forgotpasswordemail">
      <ForgotPasswordEmail Title="Reset Password" />
    </div>
  );
}

export default ForgotPasswordEmailRoute;
