import React from "react";
import SmallSpinner from "../SmallSpinner";
import { sanitizeInput, Validator } from "../Validation/Validator";
import { getAllFormElements, showValidationErrors, resetValidationErrors } from "../Utilities";
import ValidationSummary from "../Validation/ValidationSummary";

interface Props {
  onAddSubmit: any;
  IsLoading: boolean;
}
interface State {
  name: string;
  formErrors: any;
}

class CardAddForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { name: "", formErrors: [] };
    this.handleBlurValidation = this.handleBlurValidation.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
  }
  handleSubmit = (e: any) => {
    e.preventDefault();
    const name = this.state.name.trim();
    //there is inbuilt validation running in browsers
    //keep this a custom validation solution though
    let form = document.getElementById("addChildForm");
    let allFormElements = getAllFormElements({ element: form ? form : e });
    let errors = Validator({ fields: allFormElements });

    if (errors.length === 0) {
      this.props.onAddSubmit({ name: name });
      this.setState({ name: "", formErrors: [] });
    } else {
      //show notification
      this.setState({ formErrors: errors }, () => {
        showValidationErrors(this.state.formErrors);
      });
    }
  };
  handleBlurValidation = (e: any) => {
    let errors = Validator({ fields: e.target });
    if (errors.length === 0) {
      this.setState({ formErrors: [] }, () => {
        resetValidationErrors(e.target);
      });
    } else {
      this.setState({ formErrors: errors }, () => {
        showValidationErrors(this.state.formErrors);
      });
    }
  };
  handleFocus = (e: any) => {
    this.setState({ formErrors: [] }, () => {
      resetValidationErrors(e.target);
    });
  };
  handleTextChange(e: any) {
    let childName = sanitizeInput(e.target.value, e.target.type);
    this.setState({ name: childName });
  }
  render() {
    return (
      <div className="card-form">
        <h5 className="card-title">Add Child</h5>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SmallSpinner isLoading={this.props.IsLoading} actionText="Please Wait" />
            </div>
          </div>
        </div>
        <ValidationSummary Errors={this.state.formErrors} />
        <form id="addChildForm" className="form-inline" onSubmit={this.handleSubmit}>
          <div className="form-group">
            <input
              id="name"
              className="form-control border-input"
              type="text"
              placeholder="Child's First name"
              onChange={this.handleTextChange}
              onBlur={this.handleBlurValidation}
              onFocus={this.handleFocus}
              value={this.state.name}
              data-required="true"
              data-validationname="Name"
              data-id="name"
            />
            <button className="btn btn-default btn-round">Add</button>
          </div>
        </form>
      </div>
    );
  }
}

export default CardAddForm;
