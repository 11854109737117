import { MakeRequest } from "../DataUtil";
import { ChildProps, ItemProps } from "../IComponents";

export async function getAllItems(props: ChildProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "get",
    data: { querystringParams: "?childId=" + props.data.childId },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
    location: "../",
    AuthenticationRequired: true,
  };
  await MakeRequest(requestProps);
}

export async function addItem(props: ItemProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "post",
    data: { postData: props.data },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
    location: "../",
    AuthenticationRequired: true,
  };
  await MakeRequest(requestProps);
}

export async function updateItem(props: ItemProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "put",
    data: { postData: props.data },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
    location: "../",
    AuthenticationRequired: true,
  };
  await MakeRequest(requestProps);
}

export async function deleteItem(props: ItemProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "delete",
    data: { querystringParams: "?itemId=" + props.data.ItemId },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
    location: "../",
    AuthenticationRequired: true,
  };
  await MakeRequest(requestProps);
}

export async function completeItem(props: ItemProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "delete",
    data: { querystringParams: "?itemId=" + props.data.ItemId },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
    location: "../",
    AuthenticationRequired: true,
  };
  await MakeRequest(requestProps);
}
