import React from "react";
import SmallSpinner from "../SmallSpinner";
import ValidationSummary from "../Validation/ValidationSummary";
import { sanitizeInput, Validator } from "../Validation/Validator";
import { getAllFormElements, showValidationErrors, resetValidationErrors } from "../Utilities";
import { Redirect } from "../Utilities";
import { AxiosError } from "axios";
import { setToaster } from "../Toaster";
import { ToastContainer } from "react-toastify";
import { DataGrid } from "../Grid/DataGridRoles";
import { userContext } from "../Menu/MenuPicker";
import { addRole } from "../Admin/Admin";

interface Props {
  Title: string;
}

interface State {
  data: any;
  isLoading: boolean;
  name: string;
  renderCount: number;
  formErrors: any;
  noData: boolean;
  redirect: boolean;
  user: any;
}

class AddRole extends React.Component<Props, State> {
  static contextType = userContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      isLoading: false,
      name: "",
      renderCount: 0,
      formErrors: [],
      noData: true,
      redirect: false,
      user: {},
    };
    this.handleBlurValidation = this.handleBlurValidation.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleRoleDeleted = this.handleRoleDeleted.bind(this);
  }
  populateUser(): void {
    try {
      let context: any = this.context;
      this.setState({ user: context.user }, () => {});
    } catch (error: any) {}
  }
  postData(data: any): void {
    //process results from ajax call
    const successCallback = (result: any): void => {
      if (result && result !== undefined) {
        //do logic
        if (result.Success === true) {
          setToaster({
            state: { success: true },
            message: "Success: Role added",
          });

          //force a rerender of ItemsList
          //by updating the state of renderCount which is assigned as ItemsList key value
          this.setState({
            name: "",
            renderCount: this.state.renderCount + 1,
            isLoading: false,
          });
        } else {
          setToaster({ state: { error: true }, message: "Error: " + result.Message });
          this.setState({ isLoading: false });
        }
      }
    };
    //handle errors
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({ isLoading: false });
        this.setState({ isLoading: false }, () => {
          setToaster({
            state: { error: true },
            message: error.response ? error.message : "Unknown Error",
          });
        });
        if (error.response && error.response.status && error.response.status === 401) {
        }
      }
    };
    addRole({
      url: "Admin/CreateRole",
      data: data,
      successCallback,
      errorCallback,
    });
  }
  handleBlurValidation = (e: any) => {
    let errors = Validator({ fields: e.target });
    if (errors.length === 0) {
      this.setState({ formErrors: [] }, () => {
        resetValidationErrors(e.target);
      });
    } else {
      this.setState({ formErrors: errors }, () => {
        showValidationErrors(this.state.formErrors);
      });
    }
  };
  handleFocus = (e: any) => {
    this.setState({ formErrors: [] }, () => {
      resetValidationErrors(e.target);
    });
  };
  handleSubmit(e: any) {
    e.preventDefault();
    this.setState({ isLoading: true });
    const name = this.state.name.trim();
    let form = document.getElementById("addRole");
    let allFormElements = getAllFormElements({ element: form ? form : e });
    let errors = Validator({ fields: allFormElements });
    if (errors.length === 0 && this.state.user.FamilyId !== "") {
      this.postData({
        Name: name,
      });
      this.setState({ name: "", formErrors: [] });
    } else {
      this.setState({ isLoading: false, formErrors: errors }, () => {
        showValidationErrors(this.state.formErrors);
      });
    }
  }
  handleNameChange(e: any) {
    let name = sanitizeInput(e.target.value, e.target.type);
    this.setState({ name: name });
  }
  handleRoleDeleted() {
    this.setState({
      renderCount: this.state.renderCount + 1,
    });
  }
  buildColumns(): any {
    const columnHeaders = [
      {
        DataPropertyName: "Id",
        IsIdColumn: true,
        ShowColumn: false,
      },
      {
        DataPropertyName: "Name",
        ColumnAliasName: "Name",
        InputType: "text",
        IsRequired: true,
        ShowColumn: true,
        CanEdit: true,
      },
      {
        DataPropertyName: "",
        ColumnAliasName: "Actions",
        ShowColumn: true,
        IsActionsColumn: true,
      },
    ] as any;

    return columnHeaders;
  }
  componentDidMount(): void {
    this.populateUser();
  }
  render() {
    return (
      <>
        <div>
          <div className="col-md-12">
            <h3 className="title">{this.props.Title}</h3>
          </div>
          <hr></hr>
        </div>
        <div className="row">
          <div className="col-md-12 ml-auto mr-auto">
            <div className="card-form">
              <h5 className="card-title">{this.props.Title}</h5>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <SmallSpinner isLoading={this.state.isLoading} actionText="Adding Role" />
                  </div>
                </div>
              </div>
              <ValidationSummary Errors={this.state.formErrors} />
              <form id="addRole" className="form-inline" onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <input
                    id="name"
                    className="form-control border-input"
                    type="text"
                    placeholder="Role Name"
                    onChange={this.handleNameChange}
                    onBlur={this.handleBlurValidation}
                    onFocus={this.handleFocus}
                    value={this.state.name}
                    data-required="true"
                    data-validationname="Name"
                    data-id="Name"
                  />
                  <button className="btn btn-default btn-round">Add</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <DataGrid
              key={"dataGrid_" + this.state.renderCount}
              ColumnArray={this.buildColumns()}
              SelectAPI="Admin/GetRoles"
              UpdateAPI="Admin/UpdateRole"
              DeleteAPI="Admin/DeleteRole"
              MaxNumberOfRowsPerPage={50}
              GridName="roles"
            />
          </div>
        </div>
        <ToastContainer autoClose={2000} />
        {this.state.redirect ? <Redirect path="/login" isLogout={true} /> : <></>}
      </>
    );
  }
}

export default AddRole;
