import React from "react";
import SmallSpinner from "../SmallSpinner";
import CardList from "./CardList";
import CardAddForm from "./CardAddForm";
import { getChildren, addChild } from "../Family/Family";
import { setToaster } from "../Toaster";
import { ToastContainer } from "react-toastify";
import { Redirect } from "../Utilities";
import { AxiosError } from "axios";
import { userContext } from "../Menu/MenuPicker";

interface Props {
  title: string;
}

interface State {
  data: any;
  name: string;
  isLoading: boolean;
  userCulture: any;
  redirect: boolean;
  noData: boolean;
  user: any;
}

class CardGrid extends React.Component<Props, State> {
  static contextType = userContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      name: "",
      isLoading: false,
      userCulture: "en-GB",
      redirect: false,
      noData: false,
      user: {},
    };
    this.handleAddSubmit = this.handleAddSubmit.bind(this);
  }

  async loadData(): Promise<any> {
    let context: any = this.context;
    this.setState({ user: context.user }, () => {
      if (this.state.user.FamilyId !== null && this.state.user.FamilyId !== undefined) {
        const successCallback = (result: any): void => {
          if (result && result.Data) {
            this.setState({ data: result.Data, isLoading: false, noData: false });
          } else {
            this.setState({ isLoading: false, noData: true });
          }
        };
        const errorCallback = (error: AxiosError): void => {
          if (error) {
            this.setState({ isLoading: false, noData: true }, () => {
              setToaster({
                state: { error: true },
                message: error.response ? error.message : "Unknown Error",
              });
            });
            if (error.response && error.response.status && error.response.status === 401) {
              this.setState({ redirect: true });
            }
          }
        };
        if (Object.keys(this.state.user).length !== 0) {
          getChildren({
            url: "ChildService/GetAllChildren",
            familyId: this.state.user.FamilyId,
            successCallback,
            errorCallback,
          });
        }
      }
    });
  }
  componentDidMount(): void {
    this.setState({ isLoading: true }, () => {
      this.loadData();
    });
  }
  postData(data: any): void {
    //process results from ajax call
    const successCallback = (result: any): void => {
      if (result && result !== undefined) {
        //do logic
        if (result.Success === true) {
          setToaster({
            state: { success: true },
            message: "Success: Child Added",
          });

          //get the lastet data
          this.setState({ data: [] }, () => {
            this.loadData();
          });
        } else {
          setToaster({ state: { error: true }, message: "Error: " + result.Message });
        }
      }
    };
    //handle errors
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({ isLoading: false, noData: true }, () => {
          setToaster({
            state: { error: true },
            message: error.response ? error.message : "Unknown Error",
          });
        });
        if (error.response && error.response.status && error.response.status === 401) {
          this.setState({ redirect: true });
        }
      }
    };
    addChild({
      url: "ChildService/Create",
      data: { name: data.name, familyId: this.state.user.FamilyId },
      successCallback,
      errorCallback,
    });
  }
  handleAddSubmit(e: any) {
    if (e !== null && e !== undefined) {
      this.setState({ isLoading: true });
      //save the data
      this.postData(e);
    } else {
      this.setState({ isLoading: false });
    }
  }
  render() {
    return (
      <>
        <div>
          <div className="col-md-12">
            <h3 className="title">{this.props.title}</h3>
          </div>
          <hr></hr>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="card-info">
              <p>
                Here you can add children to your family group. From this page you can quickly
                add/subtract children's points and access each child's profile and item list. To add
                a child, type their name in the text box and click "Add".
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <CardAddForm onAddSubmit={this.handleAddSubmit} IsLoading={this.state.isLoading} />
          </div>
        </div>
        <hr></hr>
        <div className="container">
          <div className="col-md-12">
            <SmallSpinner isLoading={this.state.isLoading} actionText="Loading" />
          </div>
          {this.state.noData && (
            <div className="notifications">
              <div className="alert alert-info">
                <div className="container">
                  <span>No Children Yet. </span>
                </div>
              </div>
            </div>
          )}
          <CardList data={this.state.data} userCulture={this.state.userCulture} />
        </div>
        <ToastContainer autoClose={2000} />
        {this.state.redirect ? <Redirect path="/login" isLogout={true} /> : <></>}
      </>
    );
  }
}

export default CardGrid;
