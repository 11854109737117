import React from "react";

interface Props {
  Title: string;
}

const ResetEmailSent = (props: Props) => {
  return (
    <>
      <div>
        <div className="col-md-12">
          <h3 className="title">{props.Title}</h3>
        </div>
        <hr></hr>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h3>Password email has been sent</h3>
            <p>Please check your email and click on the "Reset Password" link.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetEmailSent;
