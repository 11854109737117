import axios, { AxiosError } from "axios";
import configData from "../configData.json";
import { GetTokenFromLocalStorage } from "./Account/AuthenticationContext";

const client = axios.create({});

interface RequestProps {
  url: string;
  method: string;
  data: { querystringParams?: string; postData?: {} };
  successCallback?: Function;
  errorCallback?: Function;
  location?: string;
  AuthenticationRequired?: boolean;
}

export async function Prerequisite(query: string, successCallback: any, errorCallback: any) {
  if (query !== null && query !== undefined) {
    //Dev
    //let baseUrl: string = configData.ENDPOINT_BASE;

    //prod
    let baseUrl: string = configData.PROD_ENDPOINT_BASE;

    let config: any = {};
    let bearerobj = GetTokenFromLocalStorage();
    if (bearerobj !== null && bearerobj !== undefined) {
      config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ` + bearerobj.Token,
        },
      };
    } else {
      config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
    }

    //ajax
    //get with params
    await client
      .get(baseUrl + query, config)
      .then((response) => {
        if (successCallback !== null && successCallback !== undefined) {
          successCallback(response.data);
        }
        return;
      })
      .catch((error: AxiosError) => {
        if (errorCallback !== null && errorCallback !== undefined) {
          errorCallback(error);
        }
        return;
      });
  }
}

export async function MakeRequest(props: RequestProps): Promise<any> {
  if (props !== null && props !== undefined) {
    //Dev
    //let baseUrl: string = configData.ENDPOINT_BASE;

    //prod
    let baseUrl: string = configData.PROD_ENDPOINT_BASE;

    let isAuthenticated: boolean = false;

    let config: any = {};
    let bearerobj = GetTokenFromLocalStorage();
    if (bearerobj !== null && bearerobj !== undefined) {
      config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ` + bearerobj.Token,
        },
      };
      isAuthenticated = true;
    } else {
      config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
    }

    const RequestType = async (props: RequestProps) => {
      switch (props.method) {
        case "get":
          if (props.data.querystringParams !== null && props.data.querystringParams !== undefined) {
            //get with params
            await client
              .get(baseUrl + props.url + props.data.querystringParams, config)
              .then((response) => {
                if (props.successCallback !== null && props.successCallback !== undefined) {
                  props.successCallback(response.data);
                } else {
                  return response.data;
                }
              })
              .catch((error: AxiosError) => {
                if (props.errorCallback !== null && props.errorCallback !== undefined) {
                  props.errorCallback(error);
                }
                return;
              });
          } else {
            //get without params
            await client
              .get(baseUrl + props.url, config)
              .then((response) => {
                if (props.successCallback !== null && props.successCallback !== undefined) {
                  props.successCallback(response.data);
                } else {
                  return response.data;
                }
              })
              .catch((error: AxiosError) => {
                if (props.errorCallback !== null && props.errorCallback !== undefined) {
                  props.errorCallback(error);
                }
                return;
              });
          }
          break;
        case "post":
          if (props.data.postData !== null && props.data.postData !== undefined) {
            //post
            await client
              .post(baseUrl + props.url, props.data.postData, config)
              .then((response) => {
                if (props.successCallback !== null && props.successCallback !== undefined) {
                  props.successCallback(response.data);
                } else {
                  return response.data;
                }
              })
              .catch((error: AxiosError) => {
                if (props.errorCallback !== null && props.errorCallback !== undefined) {
                  props.errorCallback(error);
                }
                return;
              });
          }
          break;
        case "put":
          if (props.data.postData !== null && props.data.postData !== undefined) {
            //put
            await client
              .put(baseUrl + props.url, props.data.postData, config)
              .then((response) => {
                if (props.successCallback !== null && props.successCallback !== undefined) {
                  props.successCallback(response.data);
                } else {
                  return response.data;
                }
              })
              .catch((error: AxiosError) => {
                if (props.errorCallback !== null && props.errorCallback !== undefined) {
                  props.errorCallback(error);
                }
                return;
              });
          }
          break;
        case "delete":
          if (props.data.querystringParams !== null && props.data.querystringParams !== undefined) {
            //delete
            await client
              .delete(baseUrl + props.url + props.data.querystringParams, config)
              .then((response) => {
                if (props.successCallback !== null && props.successCallback !== undefined) {
                  props.successCallback(response.data);
                } else {
                  return response.data;
                }
              })
              .catch((error: AxiosError) => {
                if (props.errorCallback !== null && props.errorCallback !== undefined) {
                  props.errorCallback(error);
                }
                return;
              });
          }
          break;
        default:
          //get without params
          await client
            .get(baseUrl + props.url, config)
            .then((response) => {
              if (props.successCallback !== null && props.successCallback !== undefined) {
                props.successCallback(response.data);
              } else {
                return response.data;
              }
            })
            .catch((error: AxiosError) => {
              if (props.errorCallback !== null && props.errorCallback !== undefined) {
                props.errorCallback(error);
              }
              return;
            });
      }
    };

    await RequestType(props);
  }
}
