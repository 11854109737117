import LogIn from "../components/Account/Login";
import { Redirect } from "../components/Utilities";
import { GetTokenFromLocalStorage } from "../components/Account/AuthenticationContext";

function LogInRoute(): any {
  let token = GetTokenFromLocalStorage();
  //clear home content
  let homeContent = document.getElementById("home");
  if (homeContent) {
    homeContent.innerHTML = "";
  }
  return (
    <div id="login">
      <LogIn title="Log In" />
      {token ? <Redirect path="/family" /> : <></>}
    </div>
  );
}

export default LogInRoute;
