import React from "react";
import ResetPassword from "../components/Account/ResetPassword";
import { useParams } from "react-router-dom";

function ResetPasswordRoute(): any {
  let { userid, code } = useParams();
  return (
    <div id="resetpassword">
      <ResetPassword Title="Reset Password" UserId={userid ? userid : ""} Code={code ? code : ""} />
    </div>
  );
}

export default ResetPasswordRoute;
