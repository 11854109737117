import { MakeRequest } from "../DataUtil";
import { UserProps } from "../IComponents";

export async function login(props: UserProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "post",
    data: { postData: props.data },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
  };
  await MakeRequest(requestProps);
}

export async function register(props: UserProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "post",
    data: { postData: props.data },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
  };
  await MakeRequest(requestProps);
}

export async function recaptcha(props: UserProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "post",
    data: { postData: props.data },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
  };
  await MakeRequest(requestProps);
}

export async function isAuthenticated(props: UserProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "post",
    data: { postData: props.data },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
  };
  await MakeRequest(requestProps);
}

export async function ConfirmEmailAddress(props: UserProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "post",
    data: { postData: props.data },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
  };
  await MakeRequest(requestProps);
}

export async function CheckEmailAddress(props: UserProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "post",
    data: { postData: props.data },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
  };
  await MakeRequest(requestProps);
}

export async function SendPasswordResetEmail(props: UserProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "post",
    data: { postData: props.data },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
  };
  await MakeRequest(requestProps);
}

export async function ResetUserPassword(props: UserProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "post",
    data: { postData: props.data },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
  };
  await MakeRequest(requestProps);
}
