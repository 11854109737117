import React from "react";
import SettingsForm from "./SettingsForm";

interface Props {
  title: string;
}

interface State {
  symbol: string;
}

class Settings extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      symbol: "£",
    };
    this.onCurrencyChange = this.onCurrencyChange.bind(this);
  }
  onCurrencyChange = (symbol: string) => {
    this.setState({ symbol: symbol });
  };
  render() {
    return (
      <>
        <div>
          <div className="col-md-12">
            <h3 className="title">{this.props.title}</h3>
          </div>
          <hr></hr>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="card-info">
                <h6>Points and Money</h6>
                <p>
                  If you want to create a relationship between points and money, you can do so here.
                  Simply set the number of points that equates to the monetary value you wish. For
                  example 4 points = {this.state.symbol}1 or 5 points = {this.state.symbol}10
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <SettingsForm onCurrencyChange={this.onCurrencyChange} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Settings;
