import React from "react";
import SmallSpinner from "../SmallSpinner";
import { Validator, sanitizeInput } from "../Validation/Validator";
import { getAllFormElements, showValidationErrors, resetValidationErrors } from "../Utilities";
import { setToaster } from "../Toaster";
import { ToastContainer } from "react-toastify";
import { Redirect } from "../Utilities";
import { AxiosError } from "axios";
import ValidationSummary from "../Validation/ValidationSummary";
import { getFamilyMember } from "../Family/Family";
import {
  updateUserAccount,
  deleteUserAccount,
  RemoveTokenFromLocalStorage,
} from "../Account/AuthenticationContext";
import { SmallAlert } from "../SmallAlert";
import { userContext } from "../Menu/MenuPicker";

interface Props {}

interface State {
  email: string;
  password: string;
  confirmPassword: string;
  formErrors: any;
  isDeleting: boolean;
  isLoading: boolean;
  redirect: boolean;
  renderCount: number;
  user: any;
}

class MyAccountForm extends React.Component<Props, State> {
  static contextType = userContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      formErrors: [],
      isDeleting: false,
      isLoading: false,
      redirect: false,
      renderCount: 0,
      user: {},
    };
    this.handleBlurValidation = this.handleBlurValidation.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeleting = this.handleDeleting.bind(this);
    this.handleAccountDelete = this.handleAccountDelete.bind(this);
  }

  async loadData(): Promise<any> {
    if (this.state.user.Id !== null && this.state.user.Id !== undefined) {
      const successCallback = (result: any): void => {
        if (result && result.Data && result.Data !== undefined) {
          this.setState({
            email: result.Data.UserName,
            isLoading: false,
          });
        }
      };
      const errorCallback = (error: AxiosError): void => {
        if (error) {
          this.setState({ isLoading: false });
          setToaster({
            state: { error: true },
            message: error.response ? error.message : "Unknown Error",
          });
        }
      };
      getFamilyMember({
        url: "FamilyService/GetFamilyMember",
        userId: this.state.user.Id ? this.state.user.Id : "",
        successCallback,
        errorCallback,
      });
    }
  }
  async update(data: any): Promise<any> {
    const successCallback = (result: any): void => {
      if (result && result.Success) {
        setToaster({
          state: { success: true },
          message: "Success: Account updated ",
        });
        this.setState({ isLoading: false, formErrors: [] });
      } else if (result !== null && result.Data !== null && result.Data !== undefined) {
        setToaster({
          state: { error: true },
          message: "Failed: Errors encountered",
        });
        let passwordErrors: any = [];
        result.Data.map((error: any) => {
          let valError = {
            fieldName: error.Code,
            message: error.Description,
            id: "",
          };
          passwordErrors.push(valError);
        });
        this.setState({ isLoading: false, formErrors: passwordErrors });
      } else {
        setToaster({
          state: { error: true },
          message: "Failed: Account not updated: " + result.Message,
        });
        this.setState({ isLoading: false });
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({ isLoading: false });
        setToaster({
          state: { error: true },
          message: error.response ? error.message : "Unknown Error",
        });
      }
    };
    if (Object.keys(this.state.user).length !== 0) {
      updateUserAccount({
        url: "Authentication/UpdateUserAccount",
        userId: this.state.user.Id ? this.state.user.Id : "",
        data: data,
        successCallback,
        errorCallback,
      });
    }
  }
  async delete(): Promise<any> {
    const successCallback = (result: any): void => {
      if (result && result.Success) {
        setToaster({
          state: { success: true },
          message: "Success: Account deleted ",
        });
        RemoveTokenFromLocalStorage();
        this.setState({ isLoading: false, redirect: true });
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({ isLoading: false });
        setToaster({
          state: { error: true },
          message: error.response ? error.message : "Unknown Error",
        });
      }
    };
    if (Object.keys(this.state.user).length !== 0) {
      deleteUserAccount({
        url: "Authentication/DeleteAccount",
        userId: this.state.user.Id ? this.state.user.Id : "",
        successCallback,
        errorCallback,
      });
    }
  }
  handleBlurValidation = (e: any) => {
    let errors = Validator({ fields: e.target });
    if (errors.length === 0) {
      this.setState({ formErrors: [] }, () => {
        resetValidationErrors(e.target);
      });
    } else {
      this.setState({ formErrors: errors }, () => {
        showValidationErrors(this.state.formErrors);
      });
    }
  };
  handleFocus = (e: any) => {
    this.setState({ formErrors: [] }, () => {
      resetValidationErrors(e.target);
    });
  };
  handleEmailChange = (e: any) => {
    let email = sanitizeInput(e.target.value, e.target.type);
    this.setState({ email: email });
  };
  handlePasswordChange = (e: any) => {
    this.setState({ password: e.target.value });
    //is this a required field
    let errors = Validator({ fields: e.target });
    if (errors.length === 0) {
      this.setState({ formErrors: [] }, () => {
        resetValidationErrors(e.target);
      });
    } else {
      this.setState({ formErrors: errors }, () => {
        showValidationErrors(this.state.formErrors);
      });
    }
  };
  handleConfirmPasswordChange = (e: any) => {
    this.setState({ confirmPassword: e.target.value });
    //is this a required field
    let errors = Validator({ fields: e.target });
    if (errors.length === 0) {
      this.setState({ formErrors: [] }, () => {
        resetValidationErrors(e.target);
      });
    } else {
      this.setState({ formErrors: errors }, () => {
        showValidationErrors(this.state.formErrors);
      });
    }
  };
  handleSubmit = (e: any) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const email = this.state.email.trim();
    const password = this.state.password.trim();
    const confirmPassword = this.state.confirmPassword.trim();
    let form = document.getElementById("myAccountForm");
    let allFormElements = getAllFormElements({ element: form ? form : e });
    let errors = Validator({ fields: allFormElements });

    if (password !== confirmPassword) {
      let error = { fieldName: "password", message: "Password and Confirm Password do not match" };
      errors.push(error);
    }

    if (errors.length === 0) {
      this.update({
        Id: this.state.user.Id,
        Username: email,
        Email: email,
        Password: password,
      });
      this.setState({ formErrors: [] }, () => {
        resetValidationErrors(e.target);
      });
    } else {
      this.setState({ isLoading: false, formErrors: errors }, () => {
        showValidationErrors(this.state.formErrors);
      });
    }
  };
  handleDeleting(e: any): void {
    e.preventDefault();
    this.setState({ isDeleting: true });
  }
  handleAccountDelete(e: any): void {
    e.preventDefault();
    this.setState({ isDeleting: false });
    this.delete();
  }
  componentDidMount(): void {
    let context: any = this.context;
    this.setState({ isLoading: true, user: context.user }, () => {
      this.loadData();
    });
  }
  render() {
    return (
      <>
        <div className="card-form">
          <h5 className="card-title">Update Account</h5>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <SmallSpinner isLoading={this.state.isLoading} actionText="Please Wait" />
              </div>
            </div>
          </div>
          <ValidationSummary Errors={this.state.formErrors} />
          <form id="myAccountForm" onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor="email" className="label-dark">
                Email Address
              </label>
              <input
                id="email"
                className="form-control border-input"
                type="email"
                value={this.state.email}
                onChange={this.handleEmailChange}
                onBlur={this.handleBlurValidation}
                onFocus={this.handleFocus}
                placeholder="Email Address"
                data-required="false"
                data-validationname="Email Address"
                data-id={this.state.user.Id}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password" className="label-dark">
                New Password
              </label>
              <input
                id="password"
                className="form-control border-input"
                type="password"
                value={this.state.password}
                onChange={this.handlePasswordChange}
                placeholder="Password"
                data-required="false"
                data-validationname="Password"
                data-id={this.state.user.Id}
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword" className="label-dark">
                Confirm New Password
              </label>
              <input
                id="confirm_password"
                className="form-control border-input"
                type="password"
                value={this.state.confirmPassword}
                onChange={this.handleConfirmPasswordChange}
                placeholder="Confirm Password"
                data-required="false"
                data-validationname="Confirm Password"
                data-id={this.state.user.Id}
              />
            </div>
            <div className="form-group">
              <button className="btn btn-default btn-round">Update</button>
            </div>
          </form>
          <ToastContainer autoClose={2000} />
        </div>
        <div className="col-sm-12">
          <div className="row">
            <span className="buttonSpan">
              <button
                id={"delete_" + this.state.user.Id}
                type="button"
                className="btn btn-danger btn-round col-sm-12"
                onClick={this.handleDeleting}
                data-tooltip-content="Delete Account"
                data-toggle="modal"
                data-target="#smallAlertModalUser"
                data-id={this.state.user.Id}
              >
                Delete Account
              </button>
            </span>
          </div>
        </div>
        <SmallAlert
          id="smallAlertModalUser"
          message={"Are you sure you want to delete your account?"}
          cancel="Cancel"
          confirm="Yes"
          isVisible={this.state.isDeleting}
          onDelete={this.handleAccountDelete}
        />
        {this.state.redirect && <Redirect path="/login" isLogout={true} />}
      </>
    );
  }
}

export default MyAccountForm;
