import React, { PropsWithRef } from "react";
import RegisterForm from "./RegisterForm";

interface Props {
  title: string;
}

class Register extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <div>
          <div className="col-md-12">
            <h3 className="title">{this.props.title}</h3>
          </div>
          <hr></hr>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="card-info">
                <h6>Password Policy</h6>
                <p>
                  Passwords must be at least 6 characters.
                  <br /> Have at least one non alphanumeric character.
                  <br /> Have at least one lowercase character.
                  <br />
                  Have at least one uppercase character.
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <RegisterForm />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Register;
