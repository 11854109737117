import React from "react";
import Card from "./Card";

interface Props {
  data: any;
  userCulture?: any;
}

class CardList extends React.Component<Props> {
  render() {
    if (this.props.data != null && this.props.data.length > 0) {
      const profiles = this.props.data.map((profile: any) => (
        <Card
          key={profile.ChildId}
          ChildId={profile.ChildId}
          name={profile.Name}
          TokensTotal={profile.TokensTotal}
          userCulture={this.props.userCulture}
          Items={profile.Items}
          AvailableItems={profile.AvailableItems}
          ChosenItems={profile.ChosenItems}
          Balance={profile.Balance}
          CurrencySymbol={profile.CurrencySymbol}
        />
      ));
      return <div className="row">{profiles}</div>;
    } else {
      return <></>;
    }
  }
}

export default CardList;
