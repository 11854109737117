import React from "react";
import SmallSpinner from "../SmallSpinner";
import Item from "./Item";
import { setToaster } from "../Toaster";
import { ToastContainer } from "react-toastify";
import { getAllItems, updateItem, deleteItem, completeItem } from "./Items";
import { Redirect } from "../Utilities";
import { AxiosError } from "axios";
import TokenContext from "../Context/TokenContext";

interface Props {
  ChildId: string;
  renderCount?: number;
}

interface State {
  data: any;
  isLoading: boolean;
  numberOfItems: number;
  redirect: boolean;
  noData: boolean;
}

class ItemList extends React.Component<Props, State> {
  static contextType = TokenContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      isLoading: false,
      numberOfItems: 0,
      redirect: false,
      noData: true,
    };
    this.handleItemDelete = this.handleItemDelete.bind(this);
    this.handleUpdating = this.handleUpdating.bind(this);
    this.handleCompleting = this.handleCompleting.bind(this);
  }
  loadItemData(): void {
    if (this.props.ChildId !== null && this.props.ChildId !== undefined) {
      //reload the list items
      const successCallback = (result: any): void => {
        if (result && result.Data && result.Data !== undefined) {
          this.setState({
            data: result.Data,
            isLoading: false,
            numberOfItems: result.Items ? result.Items : 0,
            noData: false,
          });
        }
      };
      const errorCallback = (error: AxiosError): void => {
        if (error) {
          this.setState({ isLoading: false, noData: true }, () => {
            setToaster({
              state: { error: true },
              message: error.response ? error.message : "Unknown Error",
            });
          });
          if (error.response && error.response.status && error.response.status === 401) {
            this.setState({ redirect: true });
          }
        }
      };
      getAllItems({
        url: "ItemService/GetAllItems",
        data: { childId: this.props.ChildId },
        successCallback,
        errorCallback,
      });
    }
  }
  updateItem(data: any): void {
    //process results from ajax call
    const successCallback = (result: any): void => {
      if (result && result !== undefined) {
        //do logic
        if (result.Success === true) {
          setToaster({
            state: { success: true },
            message: "Success: Item updated",
          });

          //update the tokenchange value in the parent context
          let context: any = this.context;
          context.updateTokenValue(true);
        } else {
          setToaster({ state: { error: true }, message: "Error: " + result.Message });
        }
      }
      this.setState({ isLoading: false });
    };
    //handle errors
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({ isLoading: false }, () => {
          setToaster({
            state: { error: true },
            message: error.response ? error.message : "Unknown Error",
          });
        });
        if (error.response && error.response.status && error.response.status === 401) {
          this.setState({ redirect: true });
        }
      }
      this.setState({ isLoading: false });
    };
    updateItem({
      url: "ItemService/UpdateItem",
      data: data,
      successCallback,
      errorCallback,
    });
  }
  deleteItem = (data: any): void => {
    //process results from ajax call
    const successCallback = (result: any): void => {
      if (result && result !== undefined) {
        //do logic
        if (result.Success === true) {
          setToaster({
            state: { success: true },
            message: "Success: Item deleted",
          });

          //update the tokenchange value in the parent context
          let context: any = this.context;
          context.updateTokenValue(true);
        } else {
          setToaster({ state: { error: true }, message: "Error: " + result.Message });
        }
      }
      this.setState({ isLoading: false });
    };
    //handle errors
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({ isLoading: false }, () => {
          setToaster({
            state: { error: true },
            message: error.response ? error.message : "Unknown Error",
          });
        });
        if (error.response && error.response.status && error.response.status === 401) {
          this.setState({ redirect: true });
        }
      }
      this.setState({ isLoading: false });
    };
    deleteItem({
      url: "ItemService/DeleteItem",
      data: data,
      successCallback,
      errorCallback,
    });
  };
  completeItem = (data: any): void => {
    //process results from ajax call
    const successCallback = (result: any): void => {
      if (result && result !== undefined) {
        //do logic
        if (result.Success === true) {
          setToaster({
            state: { success: true },
            message: "Success: Item completed",
          });

          //update the tokenchange value in the parent context
          let context: any = this.context;
          context.updateTokenValue(true);
        } else {
          setToaster({ state: { error: true }, message: "Error: " + result.Message });
        }
      }
      this.setState({ isLoading: false });
    };
    //handle errors
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({ isLoading: false }, () => {
          setToaster({
            state: { error: true },
            message: error.response ? error.message : "Unknown Error",
          });
        });
        if (error.response && error.response.status && error.response.status === 401) {
          this.setState({ redirect: true });
        }
      }
      this.setState({ isLoading: false });
    };
    completeItem({
      url: "ItemService/CompleteItem",
      data: data,
      successCallback,
      errorCallback,
    });
  };
  handleUpdating = (e: any) => {
    if (e !== null && e !== undefined) {
      //save data
      this.setState({ isLoading: true }, () => {
        this.updateItem(e);
      });
    }
  };
  handleItemDelete = (e: any) => {
    if (e !== null && e !== undefined) {
      //delete the item
      this.setState({ isLoading: true }, () => {
        this.deleteItem(e);
      });
    }
  };
  handleCompleting = (e: any) => {
    if (e !== null && e !== undefined) {
      //complete the item
      this.setState({ isLoading: true }, () => {
        this.completeItem(e);
      });
    }
  };
  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      this.loadItemData();
    });
  }
  componentWillUnmount() {
    this.setState({
      data: [],
      isLoading: true,
      numberOfItems: 0,
      redirect: false,
    });
  }
  render() {
    if (this.state.data != null && this.state.data.length > 0) {
      const items = this.state.data.map((item: any) => (
        <Item
          key={"Item_" + item.ItemId}
          ItemId={item.ItemId}
          ItemName={item.ItemName}
          NumberOfTokensNeeded={item.NumberOfTokensNeeded}
          TokensTotal={item.TokensTotal}
          targetDate={item.TargetDate}
          onDeletingItem={this.handleItemDelete}
          onUpdating={this.handleUpdating}
          onCompleting={this.handleCompleting}
          IsChosen={item.IsChosen}
        />
      ));
      return (
        <>
          <div className="col-md-12">
            <SmallSpinner isLoading={this.state.isLoading} actionText="Loading" />
          </div>
          <div id="acordeon">
            <div id="accordion" role="tablist" aria-multiselectable="true">
              <form id="itemsForm">{items}</form>
            </div>
            <ToastContainer autoClose={2000} />
            {this.state.redirect ? <Redirect path="/login" isLogout={true} /> : <></>}
          </div>
        </>
      );
    }
  }
}

export default ItemList;
