import React from "react";
import { useState, useEffect } from "react";
import { SetTokenStatus, GetTokenFromLocalStorage } from "./AuthenticationContext";
import { Redirect } from "../Utilities";

function TokenStatus(): any {
  const [token, setToken] = useState() as any;

  useEffect(() => {
    SetTokenStatus().then((result) => {
      if (result !== "") {
        let token: string | null = GetTokenFromLocalStorage();
        setToken(token);
      } else {
        setToken(result);
      }
    });
  }, []);

  if (token === null || token === undefined) {
    return <></>;
  } else if (token === "") {
    return <Redirect path="/login" isLogout={true} />;
  }

  return <></>;
}

export default TokenStatus;
