import React from "react";
import { Outlet, Link } from "react-router-dom";
import logo from "../../logo2.png";

const Menu = (): any => {
  return (
    <div>
      <div className="section">
        <nav className="navbar navbar-expand-lg fixed-top nav-down">
          <div className="container">
            <div className="navbar-translate">
              <Link className="navbar-brand" to={`/`} data-placement="bottom">
                <span>
                  <img src={logo} width={64} height={75} alt="child-reaching" />
                </span>
                {/*<span>
                  <FontAwesomeIcon icon={faChildReaching} size="4x" />
  </span>*/}
              </Link>
              <div className="navbar-brand">
                <h6>
                  kidpoint
                  <small>
                    <strong> - [ Beta ]</strong>
                  </small>
                </h6>
              </div>

              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navigation"
                aria-controls="navigation-index"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-bar bar1"></span>
                <span className="navbar-toggler-bar bar2"></span>
                <span className="navbar-toggler-bar bar3"></span>
              </button>
            </div>
            <div className="collapse navbar-collapse" data-nav-image="#" data-color="orange">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link" to={`register`}>
                    Register
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={`login`}>
                    Log In
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <div className="section">
        <div id="detail" className="container">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
export default Menu;
