import React from "react";
import SmallSpinner from "../SmallSpinner";
import { Validator } from "../Validation/Validator";
import { getAllFormElements, showValidationErrors, resetValidationErrors } from "../Utilities";
import { setToaster } from "../Toaster";
import { ToastContainer } from "react-toastify";
import { Redirect } from "../Utilities";
import { AxiosError } from "axios";
import ValidationSummary from "../Validation/ValidationSummary";
import { getCurrencySetting, updateCurrencySetting } from "../Family/Family";
import { userContext } from "../Menu/MenuPicker";

interface Props {
  onCurrencyChange: any;
}

interface State {
  points: number;
  money: number;
  currencyCode: string;
  redirect: boolean;
  formErrors: any;
  isLoading: boolean;
  selectedCurrency: string;
  user: any;
}

class SettingsForm extends React.Component<Props, State> {
  static contextType = userContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      points: 0,
      money: 0,
      currencyCode: "GBP",
      redirect: false,
      formErrors: [],
      isLoading: false,
      selectedCurrency: "£",
      user: {},
    };
    this.handleBlurValidation = this.handleBlurValidation.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePointsChange = this.handlePointsChange.bind(this);
    this.handleMoneyChange = this.handleMoneyChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
  }
  async loadData(): Promise<any> {
    if (this.state.user.Id !== null && this.state.user.Id !== undefined) {
      const successCallback = (result: any): void => {
        if (result && result.Data && result.Data !== undefined) {
          this.setState(
            {
              isLoading: false,
              points: result.Data.Points ? result.Data.Points : 0,
              money: result.Data.Money ? result.Data.Money : 0,
              currencyCode: result.Data.CurrencyCode ? result.Data.CurrencyCode : "GBP",
              selectedCurrency: result.Data.Symbol ? result.Data.Symbol : "£",
            },
            () => {
              this.props.onCurrencyChange(this.state.selectedCurrency);
            }
          );
        } else {
          this.setState({ isLoading: false });
        }
      };
      const errorCallback = (error: AxiosError): void => {
        if (error) {
          this.setState({ isLoading: false });
          setToaster({
            state: { error: true },
            message: error.response ? error.message : "Unknown Error",
          });
        }
      };
      getCurrencySetting({
        url: "FamilyService/GetCurrencySetting",
        data: { FamilyId: this.state.user.FamilyId ? this.state.user.FamilyId : "" },
        successCallback,
        errorCallback,
      });
    }
  }
  async update(data: any): Promise<any> {
    const successCallback = (result: any): void => {
      if (result && result.Success) {
        setToaster({
          state: { success: true },
          message: "Success: Currency updated ",
        });
        this.setState({ isLoading: false, formErrors: [] });
      } else if (result !== null && result.Data !== null && result.Data !== undefined) {
        setToaster({
          state: { error: true },
          message: "Failed: Errors encountered",
        });
        this.setState({ isLoading: false });
      } else {
        setToaster({
          state: { error: true },
          message: "Failed: Currency not added: " + result.Message,
        });
        this.setState({ isLoading: false });
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({ isLoading: false });
        setToaster({
          state: { error: true },
          message: error.response ? error.message : "Unknown Error",
        });
      }
    };
    if (Object.keys(this.state.user).length !== 0) {
      updateCurrencySetting({
        url: "FamilyService/UpdateCurrencySetting",
        data: data,
        successCallback,
        errorCallback,
      });
    }
  }
  handleBlurValidation = (e: any) => {
    let errors = Validator({ fields: e.target });
    if (errors.length === 0) {
      this.setState({ formErrors: [] }, () => {
        resetValidationErrors(e.target);
      });
    } else {
      this.setState({ formErrors: errors }, () => {
        showValidationErrors(this.state.formErrors);
      });
    }
  };
  handleFocus = (e: any) => {
    this.setState({ formErrors: [] }, () => {
      resetValidationErrors(e.target);
    });
  };
  handlePointsChange = (e: any) => {
    if (e.target.value < 0) {
      e.target.value = 0;
    }
    this.setState({ points: e.target.value });
  };
  handleMoneyChange = (e: any) => {
    if (e.target.value < 0) {
      e.target.value = 0;
    }
    this.setState({ money: e.target.value });
  };
  handleCurrencyChange = (e: any) => {
    let selected: any = e.target.selectedOptions;
    if (selected.length === 1) {
      this.setState({ selectedCurrency: e.target.value, currencyCode: selected[0].id }, () => {
        this.props.onCurrencyChange(e.target.value);
      });
    }
  };
  handleSubmit = async (e: any) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    let form = document.getElementById("currencyRatio");
    let allFormElements = getAllFormElements({ element: form ? form : e });
    let errors = Validator({ fields: allFormElements });

    if (errors.length === 0) {
      this.setState({ formErrors: [] });
      this.update({
        IsFamilyAdmin: true,
        FamilyId: this.state.user.FamilyId,
        Symbol: this.state.selectedCurrency,
        Points: this.state.points,
        Money: this.state.money,
        CurrencyCode: this.state.currencyCode,
      });
    } else {
      this.setState({ isLoading: false, formErrors: errors }, () => {
        showValidationErrors(this.state.formErrors);
      });
    }
  };
  componentDidMount(): void {
    let context: any = this.context;
    this.setState({ isLoading: true, user: context.user }, () => {
      this.loadData();
    });
  }
  render() {
    let selectOptions = [
      <option key="GBP" id="GBP" value="£">
        UK Pound
      </option>,
      <option key="USD" id="USD" value="$">
        US Dollar
      </option>,
      <option key="EUR" id="EUR" value="€">
        Euro
      </option>,
    ];
    return (
      <>
        <div className="card-form">
          <h5 className="card-title">Currency Settings</h5>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <SmallSpinner isLoading={this.state.isLoading} actionText="Please Wait" />
              </div>
            </div>
          </div>
          <ValidationSummary Errors={this.state.formErrors} />
          <form id="currencyRatio" onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor="email" className="label-dark">
                Select Currency
              </label>
              <select
                key="currency_select"
                name="currency"
                id="currency_select"
                className="form-control"
                value={this.state.selectedCurrency ? this.state.selectedCurrency : "£"}
                onChange={this.handleCurrencyChange}
                data-required="true"
                data-primarykeyname="CurrencyId"
                data-textvaluepropertyname="CurrencyId"
                data-validationname="currency"
              >
                {selectOptions}
              </select>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="password" className="label-dark">
                      Points
                    </label>
                    <input
                      id="points"
                      className="form-control border-input"
                      type="number"
                      value={this.state.points ? this.state.points : 0}
                      onChange={this.handlePointsChange}
                      onBlur={this.handleBlurValidation}
                      onFocus={this.handleFocus}
                      placeholder="Points"
                      data-required="true"
                      data-min="1"
                      data-validationname="Points"
                      data-id="Points"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="password" className="label-dark">
                      Money
                    </label>
                    <div className="input-group">
                      <input
                        id="money"
                        className="form-control border-input"
                        type="number"
                        value={this.state.money ? this.state.money : 0}
                        onChange={this.handleMoneyChange}
                        onBlur={this.handleBlurValidation}
                        onFocus={this.handleFocus}
                        placeholder="Money"
                        data-required="true"
                        data-min="1"
                        data-validationname="Money"
                        data-id="Money"
                      />
                      <span className="input-group-text">{this.state.selectedCurrency}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <button className="btn btn-default btn-round">Submit</button>
            </div>
          </form>
          <ToastContainer autoClose={2000} />
        </div>
      </>
    );
  }
}

export default SettingsForm;
