import React from "react";
import ConfirmEmail from "../components/Account/ConfirmEmail";
import { useParams } from "react-router-dom";

function ConfirmEmailRoute(): any {
  let { userid, code } = useParams();
  return (
    <div id="confirmemail">
      <ConfirmEmail Title="Email Confirmed" UserId={userid ? userid : ""} Code={code ? code : ""} />
    </div>
  );
}

export default ConfirmEmailRoute;
