import { RemoveTokenFromLocalStorage } from "../components/Account/AuthenticationContext";
import { Redirect } from "../components/Utilities";

const LogOutRoute = (): any => {
  RemoveTokenFromLocalStorage();
  return (
    <>
      <Redirect path="/login" isLogout={true} />
    </>
  );
};

export default LogOutRoute;
