import React from "react";
import Checkbox from "../Checkbox";
import SmallSpinner from "../SmallSpinner";
import { Validator, sanitizeInput } from "../Validation/Validator";
import { getAllFormElements, showValidationErrors, resetValidationErrors } from "../Utilities";
import { setToaster } from "../Toaster";
import { ToastContainer } from "react-toastify";
import { Redirect } from "../Utilities";
import { register, recaptcha } from "../Account/AccountUtil";
import { AxiosError } from "axios";
import ValidationSummary from "../Validation/ValidationSummary";
import configData from "../../configData.json";
import Recaptcha from "./Recaptcha";

interface Props {}

interface State {
  email: string;
  password: string;
  confirmPassword: string;
  receiveUpdates: boolean;
  redirect: boolean;
  formErrors: any;
  isLoading: boolean;
  recaptcha: any;
  recaptchaResult: boolean;
}

class RegisterForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    //dev
    //let siteKey: string = configData.RECAPTCHA_SITEKEY;
    //prod
    let siteKey: string = configData.PROD_RECAPTCHA_SITEKEY;
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      receiveUpdates: false,
      redirect: false,
      formErrors: [],
      isLoading: false,
      recaptcha: new Recaptcha(siteKey, "register"),
      recaptchaResult: false,
    };
    this.handleBlurValidation = this.handleBlurValidation.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  recaptcha = async (data: any) => {
    //process results from ajax call
    const successCallback = (result: any): void => {
      if (result && result.success) {
        this.setState({
          recaptchaResult: result.success,
        });
      } else if (result !== null && result !== undefined) {
        setToaster({
          state: { error: true },
          message: "Failed: Errors encountered in recaptcha",
        });
      } else {
        setToaster({
          state: { error: true },
          message: "Failed: Errors encountered in recaptcha: " + result.Message,
        });
      }
    };
    //handle errors
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({ isLoading: false });
        setToaster({
          state: { error: true },
          message: error.response ? error.message : "Unknown Error",
        });
      }
    };
    recaptcha({
      url: "Authentication/SiteVerify",
      data: data,
      successCallback,
      errorCallback,
    });
  };
  register = (data: any): void => {
    //process results from ajax call
    const successCallback = (result: any): void => {
      if (result && result.Success) {
        //do logic
        setToaster({
          state: { success: true },
          message: "Success: Registered",
        });
        //redirect back to login page
        this.setState({
          isLoading: false,
          redirect: true,
        });
      } else if (result !== null && result.Data !== null && result.Data !== undefined) {
        setToaster({
          state: { error: true },
          message: "Failed: Errors encountered",
        });
        let passwordErrors: any = [];
        result.Data.map((error: any) => {
          let valError = {
            fieldName: error.Code,
            message: error.Description,
            id: "",
          };
          passwordErrors.push(valError);
        });
        this.setState({ isLoading: false, formErrors: passwordErrors });
      } else {
        setToaster({
          state: { error: true },
          message: "Failed: Account not registered: " + result.Message,
        });
        this.setState({ isLoading: false });
      }
    };
    //handle errors
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({ isLoading: false });
        setToaster({
          state: { error: true },
          message: error.response ? error.message : "Unknown Error",
        });
      }
    };

    register({
      url: "Authentication/Register",
      data: data,
      successCallback,
      errorCallback,
    });
  };
  handleBlurValidation = (e: any) => {
    let errors = Validator({ fields: e.target });
    if (errors.length === 0) {
      this.setState({ formErrors: [] }, () => {
        resetValidationErrors(e.target);
      });
    } else {
      this.setState({ formErrors: errors }, () => {
        showValidationErrors(this.state.formErrors);
      });
    }
  };
  handleFocus = (e: any) => {
    this.setState({ formErrors: [] }, () => {
      resetValidationErrors(e.target);
    });
  };
  handleEmailChange = (e: any) => {
    let email = sanitizeInput(e.target.value, e.target.type);
    this.setState({ email: email });
  };
  handlePasswordChange = (e: any) => {
    this.setState({ password: e.target.value });
  };
  handleConfirmPasswordChange = (e: any) => {
    this.setState({ confirmPassword: e.target.value });
  };
  handleCheckBoxChange = (e: any) => {
    this.setState({ receiveUpdates: e.target.checked });
    //is this a required field
    let errors = Validator({ fields: e.target });
    if (errors.length === 0) {
      this.setState({ formErrors: [] }, () => {
        resetValidationErrors(e.target);
      });
    } else {
      this.setState({ formErrors: errors }, () => {
        showValidationErrors(this.state.formErrors);
      });
    }
  };
  getRecaptchaToken = async (): Promise<any> => {
    try {
      return new Promise(async (resolve: any, reject: any) => {
        let token: any = await this.state.recaptcha.getToken();
        if (token !== null && token !== undefined) {
          return resolve(token);
        } else {
          return reject("");
        }
      });
    } catch {
      return new Promise((reject: any) => {
        return reject("");
      });
    }
  };
  handleSubmit = async (e: any) => {
    e.preventDefault();
    let result: any = await this.getRecaptchaToken();

    //this still needs to be implemented
    //need to wait for this result before moving on
    if (result !== "") {
      await this.recaptcha({ Response: result });
    }

    this.setState({ isLoading: true });
    const email = this.state.email.trim();
    const password = this.state.password.trim();
    const confirmPassword = this.state.confirmPassword.trim();
    const receiveUpdates = this.state.receiveUpdates;
    let form = document.getElementById("registerForm");
    let allFormElements = getAllFormElements({ element: form ? form : e });
    let errors = Validator({ fields: allFormElements });

    if (password !== confirmPassword) {
      let error = { fieldName: "password", message: "Password and Confirm Password do not match" };
      errors.push(error);
    }

    if (errors.length === 0) {
      this.setState({ formErrors: [] });
      this.register({
        Username: email,
        Password: password,
        ReceiveUpdates: receiveUpdates,
        IsFamilyAdmin: true,
      });
    } else {
      this.setState({ isLoading: false, formErrors: errors }, () => {
        showValidationErrors(this.state.formErrors);
      });
    }
  };
  render() {
    return (
      <>
        <div className="card-form">
          <h5 className="card-title">Create Account</h5>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <SmallSpinner isLoading={this.state.isLoading} actionText="Please Wait" />
              </div>
            </div>
          </div>
          <ValidationSummary Errors={this.state.formErrors} />
          <form id="registerForm" onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor="email" className="label-dark">
                Email Address
              </label>
              <input
                id="email"
                className="form-control border-input"
                type="email"
                value={this.state.email}
                onChange={this.handleEmailChange}
                onBlur={this.handleBlurValidation}
                onFocus={this.handleFocus}
                placeholder="Email Address"
                data-required="true"
                data-validationname="Email Address"
                data-id="email"
              />
            </div>
            <div className="form-group">
              <label htmlFor="password" className="label-dark">
                Password
              </label>
              <input
                id="password"
                className="form-control border-input"
                type="password"
                value={this.state.password}
                onChange={this.handlePasswordChange}
                onBlur={this.handleBlurValidation}
                onFocus={this.handleFocus}
                placeholder="Password"
                data-required="true"
                data-validationname="Password"
                data-id="password"
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword" className="label-dark">
                Confirm Password
              </label>
              <input
                id="confirm_password"
                className="form-control border-input"
                type="password"
                value={this.state.confirmPassword}
                onChange={this.handleConfirmPasswordChange}
                onBlur={this.handleBlurValidation}
                onFocus={this.handleFocus}
                placeholder="Confirm Password"
                data-required="true"
                data-validationname="Confirm Password"
                data-id="confirm_password"
              />
            </div>
            <div className="form-group">
              <div className="form-group">
                <Checkbox
                  id="chkUpdates"
                  label="Check if you would like to receive updates"
                  checked={false}
                  onSelecting={this.handleCheckBoxChange}
                  datarequired={false}
                  labelClass="label-dark"
                  data-validationname="Updates"
                  data-id="chkUpdates"
                />
              </div>
            </div>
            <div className="form-group">
              <button
                className="btn btn-default btn-round"
                data-toggle="modal"
                data-target="#modalregisterForm"
                data-id="regButton"
              >
                Register
              </button>
            </div>
          </form>
          <ToastContainer autoClose={2000} />
          {this.state.redirect ? <Redirect path="/registerconfirmed" isLogout={true} /> : <></>}
        </div>
      </>
    );
  }
}

export default RegisterForm;
