import React from "react";
import SmallSpinner from "../SmallSpinner";
import { MakeRequest } from "../DataUtil";
import { getUsers } from "../Admin/Admin";
import { AxiosError } from "axios";
import { getAllFormElements, Redirect } from "../Utilities";
import ValidationSummary from "../Validation/ValidationSummary";
//import Select from "react-select";
import { setToaster } from "../Toaster";
import { ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { userContext } from "../Menu/MenuPicker";

const GridContext = React.createContext({}) as any;

interface DataGridProps {
  ColumnArray: [];
  SelectAPI: string;
  UpdateAPI?: string;
  DeleteAPI?: string;
  MaxNumberOfRowsPerPage?: number;
  GridName: string;
  OnDataSaved?: any;
  AppUserData: any;
}

interface DataGridState {
  renderCount: number;
  user: any;
}

interface DataGridRowCollectionProps {
  FamilyId: string;
  ColumnArray: [];
  SelectAPI: string;
  UpdateAPI?: string;
  DeleteAPI?: string;
  MaxNumberOfRowsPerPage?: number;
  GridName: string;
  OnDataSaved?: any;
  AppUserData: any;
}

interface DataGridRowCollectionState {
  data: [];
  isLoading: boolean;
  noData: boolean;
  saveEnabled: boolean;
  formErrors: any;
  numberOfViewableColumns: number;
  redirect: boolean;
}

interface DataGridRowProps {
  ColumnArray: [];
  RowData: any;
  UpdateAPI?: string;
  DeleteAPI?: string;
  OnChangedRow?: any;
  OnEdit?: any;
  OnDelete?: any;
  AppUserData: any;
}

interface DataGridRowState {
  rowId: string;
  editMode: boolean;
  primaryKeyName: string;
  selectedRole: string;
}

export class DataGrid extends React.Component<DataGridProps, DataGridState> {
  static contextType = userContext;
  constructor(props: DataGridProps) {
    super(props);
    this.state = {
      renderCount: 0,
      user: {},
    };
    this.updateKeyValue = this.updateKeyValue.bind(this);
    this.handleDataSaved = this.handleDataSaved.bind(this);
  }

  //called when children click Cancel
  //incrementing the renderCount will force child components to reload
  updateKeyValue(): void {
    this.setState({
      renderCount: this.state.renderCount + 1,
    });
  }
  handleDataSaved(data: any) {
    //send the saved data out to the calling component
    //for saving and bespoke operations
    if (this.props.OnDataSaved !== null && this.props.OnDataSaved !== undefined) {
      this.props.OnDataSaved(data);
    }
  }
  componentDidMount(): void {
    let context: any = this.context;
    this.setState({ user: context.user, renderCount: this.state.renderCount + 1 }, () => {});
  }
  render() {
    const contextValue = {
      updateKeyValue: this.updateKeyValue,
    };
    return (
      <>
        <GridContext.Provider value={contextValue}>
          <DataGridRowCollection
            key={"DataGridRowCollection_" + this.state.renderCount}
            FamilyId={this.state.user.FamilyId}
            ColumnArray={this.props.ColumnArray}
            SelectAPI={this.props.SelectAPI}
            UpdateAPI={this.props.UpdateAPI}
            DeleteAPI={this.props.DeleteAPI}
            MaxNumberOfRowsPerPage={this.props.MaxNumberOfRowsPerPage}
            GridName={this.props.GridName}
            OnDataSaved={this.handleDataSaved}
            AppUserData={this.props.AppUserData}
          />
        </GridContext.Provider>
      </>
    );
  }
}

class DataGridRowCollection extends React.Component<
  DataGridRowCollectionProps,
  DataGridRowCollectionState
> {
  static contextType = GridContext;
  constructor(props: DataGridRowCollectionProps) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      noData: false,
      saveEnabled: true,
      formErrors: [],
      numberOfViewableColumns: 0,
      redirect: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnChangedRow = this.handleOnChangedRow.bind(this);
    this.handleOnEditMode = this.handleOnEditMode.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.getColumnHeaders = this.getColumnHeaders.bind(this);
    this.buildDataObject = this.buildDataObject.bind(this);
    this.updateGridData = this.updateGridData.bind(this);
    this.deleteGridData = this.deleteGridData.bind(this);
  }

  updateGridData(data: any): void {
    const successCallback = (result: any): void => {
      if (result.Success) {
        setToaster({
          state: { success: true },
          message: "Success: Grid Updated",
        });
        //update the parent key value
        //to reload component
        let context: any = this.context;
        context.updateKeyValue();
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({ isLoading: false, noData: true }, () => {});
        //do something with error
        setToaster({
          state: { success: true },
          message: error.response ? error.message : "Unknown Error",
        });
      }
    };
    MakeRequest({
      url: this.props.UpdateAPI ? this.props.UpdateAPI : "",
      method: "put",
      data: { postData: data },
      successCallback: successCallback,
      errorCallback: errorCallback,
    });
  }
  deleteGridData(id: any): void {
    const successCallback = (result: any): void => {
      if (result.Success) {
        setToaster({
          state: { success: true },
          message: "Success: Row Deleted",
        });
        //update the parent key value
        //to reload component
        let context: any = this.context;
        context.updateKeyValue();
      }
    };
    const errorCallback = (error: AxiosError): void => {
      if (error) {
        this.setState({ isLoading: false, noData: true }, () => {});
        setToaster({
          state: { success: true },
          message: error.response ? error.message : "Unknown Error",
        });
      }
    };
    MakeRequest({
      url: this.props.DeleteAPI ? this.props.DeleteAPI : "",
      method: "delete",
      data: { querystringParams: "?id=" + id },
      successCallback: successCallback,
      errorCallback: errorCallback,
    });
  }
  getColumnHeaders(): any {
    const columns = [];
    for (let i = 0; i < this.props.ColumnArray.length; i++) {
      let column: any = this.props.ColumnArray[i];
      if (column.ShowColumn && !column.IsActionsColumn) {
        columns.push(<th key={i}>{column.ColumnAliasName}</th>);
      }
      if (
        (this.props.UpdateAPI !== null &&
          this.props.UpdateAPI !== undefined &&
          this.props.UpdateAPI !== "") ||
        (this.props.DeleteAPI !== null &&
          this.props.DeleteAPI !== undefined &&
          this.props.DeleteAPI !== "")
      ) {
        if (column.IsActionsColumn) {
          columns.push(
            <th key={i} className="text-right">
              {column.ColumnAliasName}
            </th>
          );
        }
      }
    }
    return columns;
  }
  handleOnEditMode(value: boolean): void {
    this.setState({ saveEnabled: value });
  }
  handleCancelClick(): void {
    //update the parent key value
    //to reload component
    let context: any = this.context;
    context.updateKeyValue();
  }
  handleDelete(row: any) {
    this.setState({ isLoading: true }, () => {});
    if (row !== null && row !== undefined && row.attributes !== undefined) {
      let rowId: any = row.attributes["data-rowid"].value;
      if (rowId !== null && rowId !== undefined) {
        this.deleteGridData(rowId);
      }
    }
  }
  handleOnChangedRow(e: any): void {
    let newRowDataState: any = [];
    //find the input value we need to update
    let primaryKeyName: string = e.target.attributes["data-primarykeyname"].value;

    //we need to know if the id value is a number or a string
    let idIsInt = false;
    let inputId: any = e.target.attributes["data-rowid"].value;
    if (!isNaN(inputId)) {
      idIsInt = true;
    }

    //return the new data array with the updated
    newRowDataState = this.state.data.map((item: any) => {
      //do we need to parse values as numbers?
      if (idIsInt) {
        if (
          item[primaryKeyName] &&
          item[primaryKeyName] !== undefined &&
          parseInt(item[primaryKeyName]) === parseInt(inputId)
        ) {
          item[e.target.attributes["data-textvaluepropertyname"].value] = e.target.value;
        }
      } else {
        if (
          item[primaryKeyName] &&
          item[primaryKeyName] !== undefined &&
          item[primaryKeyName] === inputId
        ) {
          item[e.target.attributes["data-textvaluepropertyname"].value] = e.target.value;
        }
      }
      return item;
    });
    if (newRowDataState.length > 0) {
      this.setState({ data: newRowDataState });
    }
  }
  handleSubmit(e: any): void {
    if (e !== null && e !== undefined) {
      e.preventDefault();
      this.setState({ isLoading: true });
      //there is inbuilt validation in some browsers
      //we want to override this and provide a custom validation solution
      let form = document.getElementById(this.props.GridName);
      let allFormElements: Element[] = getAllFormElements({
        element: form ? form : e,
      });

      this.setState({ formErrors: [] });
      let dataToUpdate: any = this.buildDataObject(allFormElements);
      if (dataToUpdate !== null && dataToUpdate !== undefined && dataToUpdate.length > 0) {
        this.updateGridData(dataToUpdate);
      }
      //optional send saved data to calling component for
      //further bespoke operations
      if (this.props.OnDataSaved !== null && this.props.OnDataSaved !== undefined) {
        this.props.OnDataSaved(dataToUpdate);
      }
    }
  }
  compareValues(stateRow: any, input: any): any {
    if (stateRow["RoleId"] !== input.attributes["RoleId"].value) {
      return stateRow;
    }
  }
  buildDataObject(inputCollection: Element[]): [] {
    let dataToUpdate: any = [];
    inputCollection.forEach((input: any) => {
      let inputPrimaryKeyName: any = input.attributes["data-primarykeyname"].value;
      let inputRowId: string = input.attributes["data-rowid"].value;
      //get the data object from the state
      let rowToUpdate: any | null = this.state.data.find(
        (row: any) => row[inputPrimaryKeyName] === inputRowId
      );
      if (rowToUpdate !== null || rowToUpdate !== undefined) {
        let rowExists: any | null = dataToUpdate.find(
          (existingRow: any) => existingRow[inputPrimaryKeyName] === inputRowId
        );
        if (rowExists === null || rowExists === undefined) {
          dataToUpdate.push(rowToUpdate);
        }
      }
    });
    return dataToUpdate;
  }
  componentDidMount(): void {
    //this.loadGridData();
    let viewableColumns: number = 0;
    this.props.ColumnArray.forEach((column: any) => {
      if (column.ShowColumn !== null && column.ShowColumn !== undefined && column.ShowColumn) {
        viewableColumns++;
      }
    });

    if (this.props.AppUserData !== null && this.props.AppUserData !== undefined) {
      this.setState({
        numberOfViewableColumns: viewableColumns,
        noData: false,
        data: this.props.AppUserData,
        isLoading: false,
      });
    } else {
      this.setState({ numberOfViewableColumns: viewableColumns, noData: true });
    }
  }
  render() {
    if (this.state.data != null && this.state.data.length > 0) {
      const rows = this.state.data.map((row: any, index) => (
        <DataGridRow
          key={index}
          ColumnArray={this.props.ColumnArray}
          RowData={row}
          UpdateAPI={this.props.UpdateAPI}
          DeleteAPI={this.props.DeleteAPI}
          OnChangedRow={this.handleOnChangedRow}
          OnEdit={this.handleOnEditMode}
          OnDelete={this.handleDelete}
          AppUserData={this.props.AppUserData}
        />
      ));
      return (
        <form id={this.props.GridName} onSubmit={this.handleSubmit}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 spinnerCell">
                <SmallSpinner isLoading={this.state.isLoading} actionText="Please Wait" />
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <ValidationSummary Errors={this.state.formErrors} />
            <table className="table">
              <thead>
                <tr>{this.getColumnHeaders()}</tr>
              </thead>
              <tbody>{rows}</tbody>
              {this.props.UpdateAPI ? (
                <tfoot>
                  <tr>
                    <td
                      colSpan={this.state.numberOfViewableColumns}
                      className="td-actions text-right"
                    >
                      {this.state.saveEnabled ? (
                        <>
                          <button className="btn btn-success btn-round">Save</button>
                        </>
                      ) : (
                        <button className="btn btn-success btn-round" disabled>
                          Save
                        </button>
                      )}
                    </td>
                  </tr>
                </tfoot>
              ) : null}
            </table>
            <ToastContainer autoClose={2000} />
            {this.state.redirect ? <Redirect path="/login" /> : <></>}
          </div>
        </form>
      );
    } else {
      return (
        <>
          {this.state.noData && (
            <div className="notifications">
              <div className="alert alert-info">
                <div className="container">
                  <span>No Data Yet. </span>
                </div>
              </div>
            </div>
          )}
        </>
      );
    }
  }
}

class DataGridRow extends React.Component<DataGridRowProps, DataGridRowState> {
  constructor(props: DataGridRowProps) {
    super(props);
    this.state = {
      rowId: "",
      editMode: false,
      primaryKeyName: "",
      selectedRole: this.props.RowData.RoleId,
    };
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleting = this.handleDeleting.bind(this);
    //this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.buildCells = this.buildCells.bind(this);
  }
  handleEditClick(e: any): void {
    this.setState({ editMode: true }, () => {
      this.props.OnEdit(true);
    });
  }
  handleDeleting = (e: any): void => {
    let row: any = e.currentTarget;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="modal-dialog modal-sm ">
            <div className="modal-content">
              <div className="modal-body text-center">
                <h5>Are you sure?</h5>
              </div>
              <div className="modal-footer">
                <div className="left-side">
                  <button type="button" className="btn btn-default btn-link" onClick={onClose}>
                    No
                  </button>
                </div>
                <div className="divider"></div>
                <div className="right-side">
                  <button
                    type="button"
                    className="btn btn-danger btn-link"
                    onClick={() => {
                      this.props.OnDelete(row);
                      onClose();
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
  };
  /*handleDeleteClick(e: any) {
    this.props.OnDelete(e);
  }*/
  handleOnChange(e: any) {
    this.props.OnChangedRow(e);
    this.setState({ selectedRole: e.target.value });
  }
  handleSelectChange(e: any) {}
  buildCells(): any {
    const cells = [];
    //loop through column array and populate cells array with jsx
    for (let i = 0; i < this.props.ColumnArray.length; i++) {
      let column: any = this.props.ColumnArray[i];

      if (column.ShowColumn) {
        let cellValue: any = this.props.RowData[column.DataPropertyName];
        let valueType = typeof cellValue;
        if (
          valueType === "boolean" &&
          column.BooleanText !== null &&
          column.BooleanText !== undefined
        ) {
          if (cellValue === true) {
            cellValue = column.BooleanText.TrueState;
          } else {
            cellValue = column.BooleanText.FalseState;
          }
        }
        if (cellValue !== null && cellValue !== undefined) {
          if (
            !this.state.editMode ||
            column.CanEdit === null ||
            column.CanEdit === undefined ||
            !column.CanEdit
          ) {
            if (column.InputType !== "select") {
              cells.push(<td key={i}>{cellValue.toString()}</td>);
            } else {
              let selectOptions: any = this.props.RowData.Roles.map((option: any) => {
                if (this.props.RowData.RoleId === option.Id) {
                  return (
                    <option key={option.Id} value={option.Id}>
                      {option.Name}
                    </option>
                  );
                } else {
                  return (
                    <option key={option.Id} value={option.Id}>
                      {option.Name}
                    </option>
                  );
                }
              });
              cells.push(
                <td key={i}>
                  <select
                    key={column.DataPropertyName + "_" + this.props.RowData.Id}
                    name="cars"
                    id={column.DataPropertyName + "_" + this.props.RowData.Id}
                    className="form-control"
                    value={this.state.selectedRole}
                    onChange={this.handleOnChange}
                    data-rowid={this.state.rowId}
                    data-required={column.IsRequired}
                    data-primarykeyname={this.state.primaryKeyName}
                    data-textvaluepropertyname={column.DataPropertyName}
                    data-validationname={column.ColumnAliasName}
                  >
                    {selectOptions}
                  </select>
                </td>
              );
            }
          }
        }
      }
    }
    return cells;
  }
  componentDidMount(): void {
    //get the id column if it exists
    let idColumn: any | null = this.props.ColumnArray.find(
      (c) => c["IsIdColumn"] !== null && c["IsIdColumn"]
    );
    this.setState({
      rowId: this.props.RowData[idColumn.DataPropertyName],
      primaryKeyName: idColumn.DataPropertyName,
    });
  }
  render() {
    return (
      <>
        <tr>{this.buildCells()}</tr>
      </>
    );
  }
}
