import React from "react";
import { useState, CSSProperties } from "react";
import BeatLoader from "react-spinners/BeatLoader";

const override: CSSProperties = {
  margin: "auto",
  display: "block",
  borderColor: "black",
  width: "100%",
};

interface Props {
  isLoading: boolean | undefined;
  actionText?: string;
}

const SmallSpinner = (props: Props): any => {
  let text: string | undefined =
    props.actionText !== "" && props.actionText !== undefined ? props.actionText : "";
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#52b2cf");
  if (props.isLoading) {
    return (
      <div className="sweet-loading">
        <div className="spinner">
          <h6>{text}</h6>
        </div>
        <BeatLoader
          color={color}
          loading={loading}
          cssOverride={override}
          size={15}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }
};

export default SmallSpinner;
