import { MakeRequest } from "../DataUtil";
import {
  UserProps,
  FamilyProps,
  FamilyMemberProps,
  ChildProps,
  CurrencySettingsProps,
} from "../IComponents";

export async function heartbeat(props: UserProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "get",
    data: {},
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
  };
  await MakeRequest(requestProps);
}

export async function getFamilyMember(props: UserProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "get",
    data: { querystringParams: "?userId=" + props.userId },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
  };
  await MakeRequest(requestProps);
}

export async function getFamilyMembers(props: FamilyMemberProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "get",
    data: { querystringParams: "?familyId=" + props.data.familyId },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
  };
  await MakeRequest(requestProps);
}

export async function getChildren(props: FamilyProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "get",
    data: { querystringParams: "?familyId=" + props.familyId },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
    AuthenticationRequired: true,
  };
  await MakeRequest(requestProps);
}

export async function addChild(props: ChildProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "post",
    data: { postData: props.data },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
    AuthenticationRequired: true,
  };
  await MakeRequest(requestProps);
}

export async function addFamilyMember(props: FamilyMemberProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "post",
    data: { postData: props.data },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
    AuthenticationRequired: true,
  };
  await MakeRequest(requestProps);
}

export async function deleteFamilyMember(props: FamilyMemberProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "delete",
    data: { querystringParams: "?userId=" + props.data.userId },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
    location: "../",
    AuthenticationRequired: true,
  };
  await MakeRequest(requestProps);
}

export async function updateTokenValue(props: ChildProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "post",
    data: { postData: props.data },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
    AuthenticationRequired: true,
  };
  await MakeRequest(requestProps);
}

export async function getChildDetails(props: ChildProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "get",
    data: { querystringParams: "?childId=" + props.data?.childId },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
    location: "../",
    AuthenticationRequired: true,
  };
  await MakeRequest(requestProps);
}

export async function updateChildName(props: ChildProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "put",
    data: { postData: props.data },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
    location: "../",
    AuthenticationRequired: true,
  };
  await MakeRequest(requestProps);
}

export async function deleteChild(props: ChildProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "delete",
    data: { querystringParams: "?childId=" + props.data },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
    location: "../",
    AuthenticationRequired: true,
  };
  await MakeRequest(requestProps);
}

export async function updateCurrencySetting(props: CurrencySettingsProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "put",
    data: { postData: props.data },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
    AuthenticationRequired: true,
  };
  await MakeRequest(requestProps);
}

export async function getCurrencySetting(props: CurrencySettingsProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "get",
    data: { querystringParams: "?familyId=" + props.data?.FamilyId },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
    AuthenticationRequired: true,
  };
  await MakeRequest(requestProps);
}
