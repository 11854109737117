import React from "react";

interface Props {
  Title: string;
}

const RegisterConfirmed = (props: Props): any => {
  return (
    <>
      <div>
        <div className="col-md-12">
          <h3 className="title">{props.Title}</h3>
        </div>
        <hr></hr>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h3>Thanks! You have successfully registered</h3>
            <p>
              Please check your email (including any spam or junk mail folders). You are required to
              confirm your email address before signing in.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterConfirmed;
