import React from "react";
import { Outlet, Link } from "react-router-dom";
import logo from "../../logo2.png";

interface Props {
  User: any;
}

const SecureMenu = (props: Props) => {
  return (
    <div>
      <div className="section">
        <nav className="navbar navbar-expand-lg fixed-top nav-down">
          <div className="container">
            <div className="navbar-translate">
              <Link className="navbar-brand" to={`/`} data-placement="bottom">
                <span>
                  <img src={logo} width={64} height={75} alt="child-reaching" />
                </span>
              </Link>
              <a
                className="navbar-brand"
                href="/"
                rel="tooltip"
                title="@Resources.Menu.Menu_Title"
                data-placement="bottom"
              >
                <h6>
                  kidpoint
                  <small>
                    <strong> - [ Beta ]</strong>
                  </small>
                </h6>
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navigation"
                aria-controls="navigation-index"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-bar bar1"></span>
                <span className="navbar-toggler-bar bar2"></span>
                <span className="navbar-toggler-bar bar3"></span>
              </button>
            </div>
            <div className="collapse navbar-collapse" data-nav-image="#" data-color="orange">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link" to={`family`}>
                    My Family
                  </Link>
                </li>
                <li className="dropdown nav-item">
                  <a
                    className="dropdown-toggle nav-link"
                    href="#"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Account
                  </a>
                  <div className="dropdown-menu dropdown-menu-right dropdown-danger">
                    <Link className="dropdown-item" to={`myaccount`}>
                      My Account
                    </Link>
                    {props.User.user.IsFamilyAdmin && (
                      <>
                        <Link className="dropdown-item" to={`familymembers`}>
                          Family Members
                        </Link>
                        <Link className="dropdown-item" to={`settings`}>
                          Settings
                        </Link>
                      </>
                    )}
                    {props.User.user.IsSystemAdmin && (
                      <>
                        <Link className="dropdown-item" to={`addrole`}>
                          Add Role
                        </Link>
                        <Link className="dropdown-item" to={`addusertorole`}>
                          User Roles
                        </Link>
                        <Link className="dropdown-item" to={`users`}>
                          Users
                        </Link>
                      </>
                    )}

                    <Link className="dropdown-item" to={`logout`}>
                      Log Out
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <div className="section">
        <div id="detail" className="container">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default SecureMenu;
