import { MakeRequest } from "../DataUtil";
import { RoleProps } from "../IComponents";

export async function getRoles(props: RoleProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "get",
    data: {},
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
  };
  await MakeRequest(requestProps);
}

export async function addRole(props: RoleProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "post",
    data: { postData: props.data },
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
    AuthenticationRequired: true,
  };
  await MakeRequest(requestProps);
}

export async function getUsers(props: RoleProps): Promise<any> {
  let requestProps = {
    url: props.url,
    method: "get",
    data: {},
    successCallback: props.successCallback,
    errorCallback: props.errorCallback,
  };
  await MakeRequest(requestProps);
}
