import React from "react";
import { AxiosError } from "axios";
import { useState, useEffect, useCallback } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Root from "./routes/root";
import LogInRoute from "./routes/logIn";
import LogOutRoute from "./routes/logout";
import RegisterRoute from "./routes/register";
import ErrorPage from "./error-page";
import ContactRoute from "./routes/contact";
import FamilyRoute from "./routes/family";
import ChildDetailsRoute from "./routes/childdetails";
import FamilyMembersRoute from "./routes/familymembers";
import ConfirmEmailRoute from "./routes/confirmemail";
import ForgotPasswordEmailRoute from "./routes/forgotpasswordemail";
import ResetPasswordRoute from "./routes/resetpassword";
import ResetPasswordConfirmedRoute from "./routes/resetpasswordconfirmed";
import MyAccountRoute from "./routes/myaccount";
import ResetEmailSentRoute from "./routes/resetemailsent";
import RegisterConfirmedRoute from "./routes/registerconfirmed";
import AddRoleRoute from "./routes/addrole";
import AddUserToRoleRoute from "./routes/addusertorole";
import UsersRoute from "./routes/users";
import SettingsRoute from "./routes/settings";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "family",
        element: <FamilyRoute />,
      },
      {
        path: "childdetails/:childId",
        element: <ChildDetailsRoute />,
      },
      {
        path: "login",
        element: <LogInRoute />,
      },
      {
        path: "familymembers",
        element: <FamilyMembersRoute />,
      },
      {
        path: "logout",
        element: <LogOutRoute />,
      },
      {
        path: "register",
        element: <RegisterRoute />,
      },
      {
        //path: "contacts/:contactId",
        path: "contacts",
        element: <ContactRoute />,
      },
      {
        path: "confirmemail/:userid/:code",
        element: <ConfirmEmailRoute />,
      },
      {
        path: "forgotpasswordemail",
        element: <ForgotPasswordEmailRoute />,
      },
      {
        path: "resetpassword/:userid/:code",
        element: <ResetPasswordRoute />,
      },
      {
        path: "resetpasswordconfrimed",
        element: <ResetPasswordConfirmedRoute />,
      },
      {
        path: "myaccount",
        element: <MyAccountRoute />,
      },
      {
        path: "resetemailsent",
        element: <ResetEmailSentRoute />,
      },
      {
        path: "registerconfirmed",
        element: <RegisterConfirmedRoute />,
      },
      {
        path: "addrole",
        element: <AddRoleRoute />,
      },
      {
        path: "addusertorole",
        element: <AddUserToRoleRoute />,
      },
      {
        path: "users",
        element: <UsersRoute />,
      },
      {
        path: "settings",
        element: <SettingsRoute />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <>
    <RouterProvider router={router} />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
